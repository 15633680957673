.timeline {
    position: relative;
    padding: 2em 0;
    margin-top: 2em;
    margin-bottom: 2em;
}

.timeline::before {
    content: '';
    position: absolute;
    top: 0;
    left: 25px;
    height: 100%;
    width: 2px;
    background: $color-base-1;
}

@media only screen and (min-width: 1170px) {
    .timeline {
        margin-top: 3em;
        margin-bottom: 3em;
    }
    .timeline::before {
        left: 50%;
        margin-left: -2px;
    }
    .timeline-one-side::before {
        left: 0;
    }
}

.timeline-block {
    position: relative;
    margin: 2em 0;
}

.timeline-block:after {
    content: "";
    display: table;
    clear: both;
}

.timeline-block:first-child {
    margin-top: 0;
}

.timeline-block:last-child {
    margin-bottom: 0;
}

.timeline-img {
    position: absolute;
    left: 18px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: $color-white;
    border: 2px solid $color-base-1;
}

@media only screen and (min-width: 1170px) {
    .timeline-img {
        left: 50%;
        margin-left: -9px;
        /* Force Hardware Acceleration in WebKit */
        -webkit-transform: translateZ(0);
        -webkit-backface-visibility: hidden;
    }
    .timeline-one-side .timeline-img {
        left: 0;
    }
}

.timeline-content {
    position: relative;
    margin-left: 60px;
    margin-right: 30px;
    background: $card-bg;
    border-radius: $card-border-radius;
    border: 1px solid $card-border-color;
}

.timeline-body {
    padding: 1.5rem;
}

.timeline-content:after {
    content: "";
    display: table;
    clear: both;
}

@media only screen and (min-width: 1170px) {
    .timeline-content {
        margin-left: 0;
        width: 38%;
        margin: 0 5%
    }

    .timeline-body {
        padding: 1.5rem;
    }

    .timeline-block:nth-child(even) .timeline-content {
        float: right;
    }

    .timeline-one-side .timeline-block:nth-child(even) .timeline-content {
        float: none;
    }
}