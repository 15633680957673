// General styling
.form-control:disabled, input[type=checkbox]:disabled, input[type=radio]:disabled {
    cursor: not-!important;
}
label {
    font-weight: $form-label-font-weight;
    font-size: $form-label-font-size;
    text-transform: $form-label-text-transform;
    color: $form-label-color;
}

textarea[resize="none"] {
    resize: none!important;
}

textarea[resize="both"] {
    resize: both!important;
}

textarea[resize="vertical"] {
    resize: vertical!important;
}

textarea[resize="horizontal"] {
    resize: horizontal!important;
}
// Form control
.form-control,
.form-control > .btn {
    font-size: $form-control-font-size;
    font-weight: $form-control-font-weight;
    color: $form-control-color;
    background-color: $form-control-bg;
    border-width: $form-control-border-width;
    border-color: $form-control-border-color;
    border-radius: $form-control-border-radius;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: background-color 0.2s linear;
    -ms-transition: background-color 0.2s linear;
    transition: background-color 0.2s linear;
}

.form-control:focus {
    box-shadow: none;
}

.form-group textarea.form-control,
textarea.form-control {
    height: auto;
}
// Form control sizes

.form-control-lg,
.input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
    padding: 0.75rem 1rem;
    font-size: $form-control-font-size;
    line-height: 1.5;
    border-radius: $form-control-border-radius;
}

.input-group-btn:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.form-control-xl,
.input-group-xl > .form-control,
.input-group-xl > .input-group-addon,
.input-group-xl > .input-group-btn > .btn {
    padding: 1.25rem 1rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 3px;
}

.input-group-lg > .input-group-btn > select.btn:not([size]):not([multiple]),
.input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > select.input-group-addon:not([size]):not([multiple]),
select.form-control-lg:not([size]):not([multiple]) {
    height: 2.9375rem!important;
}
// Input groups

.input-group-addon {
    //padding: 0.5rem 0.875rem;
    color: $form-control-color;
    background-color: $form-control-bg;
    border-color: $form-control-border-color;
    -webkit-transition: background-color 0.2s linear;
    -ms-transition: background-color 0.2s linear;
    transition: background-color 0.2s linear;
}

.input-group--style-1 .form-control:not(:first-child) {
    border-left: 0;
}

.input-group--style-1 .form-control:not(:last-child) {
    border-right: 0;
}

.input-group--style-1 .input-group-addon {
    padding: 0.1875rem 0.875rem;
}

.input-group--style-1 .input-group-addon > i {
    font-size: 1.375rem;
    line-height: 1.4;
}

.input-group--style-2 .form-control {
    border-left: 0;
    border-right: 0;
}

.input-group--style-2 .form-control:focus {
    background: transparent;
}

.input-group--style-2 .input-group-btn > .btn {
    border-radius: 50%;
    background: transparent;
    border-color: $form-control-border-color;
    color: $color-gray-light;
    font-size: 0.625rem;
    padding-top: 0.6875rem;
    padding-bottom: 0.6875rem;
    cursor: pointer;
}

.input-group--style-2 .input-group-btn > .btn[disabled] {
    color: $color-gray-lighter;
}

.input-group--style-2 .input-group-btn > .btn:focus {
    box-shadow: none;
}

.input-group--style-2 .input-group-btn:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left-color: $form-control-bg;
}

.input-group--style-2 .input-group-btn:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right-color: $form-control-bg;
}
// Form Default

.form-default .form-control {
    background: $form-control-bg;
    border-color: $form-control-border-color;
    color: $form-control-color;
}

.form-default .form-control:focus,
.form-default .input-group--style-1 .form-control:focus + .input-group-addon {
    background: $form-control-bg-focus;
    border-color: $form-control-border-color-focus;
}

.form-control:disabled,
.form-control[readonly] {
    background: $form-control-disabled-bg;
}

.form-default .form-control::-webkit-input-placeholder
 {
    color: $form-control-placeholder-color!important;
}


.form-default select.form-control:invalid  {
    color: $form-control-placeholder-color!important;
}

.form-default .form-control:-moz-placeholder {
    /* Firefox 18- */
    color: $form-control-placeholder-color;
}

.form-default .form-control::-moz-placeholder {
    /* Firefox 19+ */
    color: $form-control-placeholder-color;
}

.form-default .form-control:-ms-input-placeholder {
    color: $form-control-placeholder-color;
}

.form-default .input-group--style-1 .input-group-addon {
    color: $form-control-placeholder-color;
}
// Form base 1

.form-base-1 .form-control,
.form-base-1 .input-group-addon {
    background: darken($color-base-1, 10%);
    border-color: darken($color-base-1, 12%);
    color: $color-base-text-1;
}

.form-base-1 .form-control:focus {
    background: darken($color-base-1, 10%);
    border-color: darken($color-base-1, 12%);
    color: $color-base-text-1;
}

.form-base-1 .form-control::-webkit-input-placeholder {
    color: transparentize($color-base-text-1, 0.5);
}

.form-base-1 .form-control:-moz-placeholder {
    color: transparentize($color-base-text-1, 0.5);
}

.form-base-1 .form-control::-moz-placeholder {
    color: transparentize($color-base-text-1, 0.5);
}

.form-base-1 .form-control:-ms-input-placeholder {
    color: transparentize($color-base-text-1, 0.5);
}
// Form base 2

.form-base-2 .form-control {
    background: darken($color-base-2, 10%);
    border-color: darken($color-base-2, 12%);
    color: $color-base-text-2;
}

.form-base-2 .form-control:focus {
    background: darken($color-base-2, 10%);
    border-color: darken($color-base-2, 12%);
    color: $color-base-text-2;
}

.form-base-2 .form-control::-webkit-input-placeholder {
    color: transparentize($color-base-text-2, 0.5);
}

.form-base-2 .form-control:-moz-placeholder {
    color: transparentize($color-base-text-2, 0.5);
}

.form-base-2 .form-control::-moz-placeholder {
    color: transparentize($color-base-text-2, 0.5);
}

.form-base-2 .form-control:-ms-input-placeholder {
    color: transparentize($color-base-text-2, 0.5);
}
// Form Inverse: alternative background for form elements (e.g dark)
.form-inverse label {
    color: rgba(255, 255, 255, 0.7);
}
.form-inverse .form-control {
    background: darken($color-space-gray, 3%);
    border-color: lighten($color-space-gray, 5%);
    color: $color-gray-light;
}

.form-inverse .form-control:focus {
    background: darken($color-space-gray, 3%);
    border-color: $color-white;
}

.form-inverse .form-control::-webkit-input-placeholder {
    color: invert($form-control-placeholder-color);
}

.form-inverse .form-control:-moz-placeholder {
    /* Firefox 18- */
    color: invert($form-control-placeholder-color);
}

.form-inverse .form-control::-moz-placeholder {
    /* Firefox 19+ */
    color: invert($form-control-placeholder-color);
}

.form-inverse .form-control:-ms-input-placeholder {
    color: invert($form-control-placeholder-color);
}
// Form Material: Just add .form-material next to .form-default or .form-inverse to see the result
.form-material .bootstrap-select .btn-default,
.form-material .form-control {
    background: transparent;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    border-width: 1px;
    padding-left: 1px;
    padding-right: 1px;
}

.form-material .form-control:focus {
    background: transparent;
}
.form-material .bar {
    position: relative;
    display: block;
}

.form-material .bar:after,
.form-material .bar:before {
    content: '';
    height: 2px;
    width: 0;
    bottom: 0;
    position: absolute;
    background: $color-base-1;
    transition: 0.5s ease all;
}

.form-material .bar:before {
    left: 50%;
}

.form-material .bar:after {
    right: 50%;
}

.form-material .form-control:focus ~ .bar:after,
.form-material .form-control:focus ~ .bar:before {
    width: 50%;
}

.form-inverse.form-material .form-control {
    border-color: transparentize($form-control-border-color, 0.5);
    color: rgba(255, 255, 255, 1);
}

.form-inverse.form-material .form-control:focus {
    background: transparent;
}

.form-inverse.form-material .form-control:focus {
    background: transparent;
    border-color: invert($form-control-border-color);
}


// .bg-base-1 .form-default.form-material .form-control::-webkit-input-placeholder {
//     color: rgba(255, 255, 255, 0.9);
// }
//
// .bg-base-1 .form-default.form-material .form-control:-moz-placeholder {
//     /* Firefox 18- */
//     color: rgba(255, 255, 255, 0.9);
// }
//
// .bg-base-1 .form-default.form-material .form-control::-moz-placeholder {
//     /* Firefox 19+ */
//     color: rgba(255, 255, 255, 0.9);
// }
//
// .bg-base-1 .form-default.form-material .form-control:-ms-input-placeholder {
//     color: rgba(255, 255, 255, 0.9);
// }

// .bg-base-1 .form-default.form-material .bar {
//     background: rgba(255, 255, 255, 0.6);
// }
//
// .bg-base-1 .form-default.form-material .bar:after,
// .bg-base-1 .form-default.form-material .bar:before {
//     background: rgba(255, 255, 255, 1);
// }


// Floating labels */

.has-floating-label {
    padding-top: 10px;
}

.has-floating-label .control-label {
    font-size: $font-size-sm;
    pointer-events: none;
    position: absolute;
    top: 0;
    transform: translate3d(0, 24px, 0) scale(1);
    transform-origin: left top;
    transition: 0.3s;
}

.has-floating-label.focused .control-label {
    transform: scale(0.75);
}
/* CUSTOM CHECKBOXES AND RADIOS */
.checkbox {
    outline: none;
}

.checkbox-inline {
    display: inline-block;
    margin-right: 1.125rem;
}

.checkbox label {
    display: inline-block;
    vertical-align: top;
    position: relative;
    padding-left: 0.75rem;
    margin-bottom: 0.8rem;
    font-size: $form-control-font-size;
    line-height: 18px;
    cursor: pointer;
}

.checkbox label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 19px;
    height: 19px;
    left: 0;
    margin-left: -15px;
    border: 1px solid $form-control-border-color;
    border-radius: $form-control-border-radius;
    background-color: $form-control-bg;
    -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}

.checkbox label::after {
    display: inline-block;
    position: absolute;
    width: 20px;
    height: 20px;
    left: 0;
    top: 0;
    margin-left: -15px;
    padding-left: 3px;
    padding-top: 0;
    font-size: 12px;
    color: transparent;
}

.checkbox input[type="checkbox"],
.checkbox input[type="radio"] {
    opacity: 0;
    z-index: 1;
}

.checkbox input[type="checkbox"]:checked + label::before,
.checkbox input[type="radio"]:checked + label::before {
    border-color: $color-base-1;
}

.checkbox input[type="checkbox"]:checked + label::after,
.checkbox input[type="radio"]:checked + label::after {
    color: $color-base-1;
    font-family: "FontAwesome";
    content: "\f00c";
}

.checkbox input[type="checkbox"]:disabled + label,
.checkbox input[type="radio"]:disabled + label {
    opacity: 0.65;
}

.checkbox input[type="checkbox"]:disabled + label::before,
.checkbox input[type="radio"]:disabled + label::before {
    background-color: $form-control-disabled-bg;
    cursor: not-allowed;
}

.checkbox.checkbox-circle label::before {
    border-radius: 50%;
}

.checkbox.checkbox-inline {
    margin-top: 0;
}

.checkbox-success input[type="checkbox"]:checked + label::before,
.checkbox-success input[type="radio"]:checked + label::before {
    background-color: #5cb85c;
    border-color: #5cb85c;
}

.checkbox-success input[type="checkbox"]:checked + label::after,
.checkbox-success input[type="radio"]:checked + label::after {
    color: #fff;
}

.has-success input[type="checkbox"] + label::before,
.has-success input[type="radio"] + label::before {
    border-color: #5cb85c;
}

.checkbox-primary input[type="checkbox"]:checked + label::before,
.checkbox-primary input[type="radio"]:checked + label::before {
    background-color: $color-base-1;
    border-color: $color-base-1;
}

.checkbox-primary input[type="checkbox"]:checked + label::after,
.checkbox-primary input[type="radio"]:checked + label::after {
    color: $color-base-text-1;
}

.checkbox-warning input[type="checkbox"]:checked + label::before,
.checkbox-warning input[type="radio"]:checked + label::before {
    background-color: #ec971f;
    border-color: #ec971f;
}

.checkbox-warning input[type="checkbox"]:checked + label::after,
.checkbox-warning input[type="radio"]:checked + label::after {
    color: #fff;
}

.has-warning input[type="checkbox"] + label::before,
.has-warning input[type="radio"] + label::before {
    border-color: #ec971f;
}

.checkbox-danger input[type="checkbox"]:checked + label::before,
.checkbox-danger input[type="radio"]:checked + label::before {
    background-color: #c9302c;
    border-color: #c9302c;
}

.checkbox-danger input[type="checkbox"]:checked + label::after,
.checkbox-danger input[type="radio"]:checked + label::after {
    color: #fff;
}

.has-error input[type="checkbox"] + label::before,
.has-error input[type="radio"] + label::before {
    border-color: #c9302c;
}
/* Radio inputs */
.radio {
    //padding-left: 20px;
    outline: none;
}

.radio-inline {
    display: inline-block;
    margin-right: 10px;
}

.radio label {
    display: inline-block;
    vertical-align: top;
    font-size: $form-control-font-size;
    line-height: 18px;
    position: relative;
    padding-left: 0.75rem;
    cursor: pointer;
}

.radio label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 17px;
    height: 17px;
    left: 0;
    margin-left: -15px;
    border: 2px solid $form-control-border-color;
    border-radius: 50%;
    background-color: $form-control-bg;
    -webkit-transition: border 0.15s ease-in-out;
    -o-transition: border 0.15s ease-in-out;
    transition: border 0.15s ease-in-out;
}

.radio label::after {
    display: inline-block;
    position: absolute;
    content: " ";
    width: 11px;
    height: 11px;
    left: 3px;
    top: 3px;
    margin-left: -15px;
    border-radius: 50%;
    background-color: $form-control-color-focus;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
    -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
}

.radio input[type="radio"] {
    opacity: 0;
    z-index: 1;
}

.radio input[type="radio"]:focus + label::before {
    outline: none;
}

.radio input[type="radio"]:checked + label::after {
    background-color: $color-base-1;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
}

.radio input[type="radio"]:disabled + label {
    opacity: 0.65;
}

.radio input[type="radio"]:disabled + label::before {
    cursor: not-allowed;
}

.radio.radio-inline {
    margin-top: 0;
}

.radio-primary input[type="radio"] + label::after {
    background-color: $color-base-1;
}

.radio-primary input[type="radio"]:checked + label::before {
    border-color: $color-base-1;
}

.radio-primary input[type="radio"]:checked + label::after {
    background-color: $color-base-1;
}

.radio-danger input[type="radio"] + label::after {
    background-color: #d9534f;
}

.radio-danger input[type="radio"]:checked + label::before {
    border-color: #d9534f;
}

.radio-danger input[type="radio"]:checked + label::after {
    background-color: #d9534f;
}

.radio-info input[type="radio"] + label::after {
    background-color: #5bc0de;
}

.radio-info input[type="radio"]:checked + label::before {
    border-color: #5bc0de;
}

.radio-info input[type="radio"]:checked + label::after {
    background-color: #5bc0de;
}

.radio-warning input[type="radio"] + label::after {
    background-color: #f0ad4e;
}

.radio-warning input[type="radio"]:checked + label::before {
    border-color: #f0ad4e;
}

.radio-warning input[type="radio"]:checked + label::after {
    background-color: #f0ad4e;
}

.radio-success input[type="radio"] + label::after {
    background-color: #5cb85c;
}

.radio-success input[type="radio"]:checked + label::before {
    border-color: #5cb85c;
}

.radio-success input[type="radio"]:checked + label::after {
    background-color: #5cb85c;
}
// Switch toggles
.toggle-switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
}
/* Hide default HTML checkbox */
.toggle-switch input {
    display: none;
}
/* The slider */
.toggle-switch-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid $form-control-border-color;
    background-color: transparent;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.toggle-switch input:disabled + .toggle-switch-slider {
    border: 1px solid $form-control-disabled-bg;
}
.toggle-switch-slider:disabled {

}
.toggle-switch-slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 1px;
    bottom: 2px;
    background-color: $form-control-disabled-bg;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .toggle-switch-slider:before {
    background: $color-base-1;
    border-color: $color-base-1;
}

input:checked + .toggle-switch-slider {
    border: 1px solid $color-base-1;
}

input:focus + .toggle-switch-slider {
    box-shadow: 0 0 1px $color-base-1;
}

input:checked + .toggle-switch-slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}
/* Rounded sliders */
.toggle-switch-slider.round {
    border-radius: 34px;
}

.toggle-switch-slider.round:before {
    border-radius: 50%;
}
// Product size (square) - custom styles for radio/checkbox input

.checkbox-alphanumeric::after,
.checkbox-alphanumeric::before {
    content: '';
    display: table;
}

.checkbox-alphanumeric::after {
    clear: both;
}

.checkbox-alphanumeric input {
    left: -9999px;
    position: absolute;
}

.checkbox-alphanumeric label {
    width: 2.25rem;
    height: 2.25rem;
    float: left;
    padding: 0.375rem 0;
    margin-right: 0.375rem;
    display: block;
    color: $color-gray-light;
    font-size: $font-size-sm;
    font-weight: 400;
    text-align: center;
    background: transparent;
    text-transform: uppercase;
    border: 1px solid $form-control-border-color;
    border-radius: $form-control-border-radius;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
    transform: scale(0.95);
}

.checkbox-alphanumeric-circle label {
    border-radius: 100%;
}

.checkbox-alphanumeric label > img {
    max-width: 100%;
}

.checkbox-alphanumeric label:hover {
    cursor: pointer;
    border-color: lighten($color-base-1, 15%);
}

.checkbox-alphanumeric input:checked ~ label {
    color: $color-base-1;
    border-color: $color-base-1;
    transform: scale(1.1);
}
// Product size - STYLE 1 (fluid)

.checkbox-alphanumeric--style-1 label {
    width: auto;
    padding-left: $spacer;
    padding-right: $spacer;
    border-radius: $btn-border-radius;
}

.d-table.checkbox-alphanumeric--style-1 {
    width: 100%;
}

.d-table.checkbox-alphanumeric--style-1 label {
    width: 100%;
}
/* CUSTOM COLOR INPUT */
.checkbox-color::after,
.checkbox-color::before {
    content: '';
    display: table;
}

.checkbox-color::after {
    clear: both;
}

.checkbox-color input {
    left: -9999px;
    position: absolute;
}

.checkbox-color label {
    width: 2.25rem;
    height: 2.25rem;
    float: left;
    padding: 0.375rem;
    margin-right: 0.375rem;
    display: block;
    font-size: $font-size-sm;
    text-align: center;
    opacity: 0.7;
    border: 1px solid transparent;
    border-radius: $form-control-border-radius;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
    transform: scale(0.95);
}

.checkbox-color-circle label {
    border-radius: 100%;
}

.checkbox-color label:hover {
    cursor: pointer;
    opacity: 1;
}

.checkbox-color input:checked ~ label {
    transform: scale(1.1);
    opacity: 1;
}

.checkbox-color input:checked ~ label:after {
    content: "\f121";
    font-family: "Ionicons";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: rgba(255, 255, 255, .7);
    font-size: 14px;
}
/* File inputs */
.custom-input-file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    outline: none;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.custom-input-file + label {
    max-width: 80%;
    font-size: $btn-font-size;
    font-weight: $btn-font-weight;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: block;
    overflow: hidden;
    padding: 0.625rem 1.25rem;
    border: 1px solid $color-base-1;
    border-radius: $form-control-border-radius;
    color: $color-base-text-1;
    background-color: $color-base-1;
    outline: none;
}

.custom-input-file + label:hover,
.custom-input-file.has-focus + label,
.custom-input-file:focus + label {
    background-color: darken($color-base-1, 10%);
}

.no-js .custom-input-file + label {
    display: none;
}

.custom-input-file.has-focus + label,
.custom-input-file:focus + label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
}

.custom-input-file + label i {
    width: 1em;
    height: 1em;
    vertical-align: middle;
    fill: currentColor;
    margin-top: -0.25em;
    margin-right: 0.25em;
}

/* Custom file input -- Style 2 */
.custom-input-file--2 + label {
    color: $color-base-1;
    border-color: $color-base-1;
    background: transparent;
}

.custom-input-file--2 + label:hover,
.custom-input-file--2.has-focus + label,
.custom-input-file--2:focus + label {
    color: darken($color-base-1, 10%);
    border-color: darken($color-base-1, 10%);
    background: transparent;
}

/* Custom file input -- Style 3 */
.custom-input-file--3 + label {
    background: transparent;
    border: 0;
    color: $color-base-1;
}

.custom-input-file--3 + label:hover,
.custom-input-file--3.has-focus + label,
.custom-input-file--3:focus + label {
    background: transparent;
    border: 0;
    color: darken($color-base-1, 15%);
}

/* Custom file input -- Style 4 */
.custom-input-file--4 + label {
    color: $color-base-1;
    background: transparent;
    border-color: $color-base-1;
    padding: 0;
}


.custom-input-file--4 + label:hover,
.custom-input-file--4.has-focus + label,
.custom-input-file--4:focus + label {
    border-color: darken($color-base-1, 15%);
    background: transparent;
}

.custom-input-file--4 + label span,
.custom-input-file--4 + label strong {
    padding: 0.625rem 1.25rem;
}

.custom-input-file--4 + label span {
    min-height: 2em;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: top;
}

.custom-input-file--4 + label strong {
    float: right;
    height: 100%;
    color: $color-base-text-1;
    background-color: $color-base-1;
    display: inline-block;
    font-weight: $btn-font-weight;
}

.custom-input-file--4 + label:hover strong,
.custom-input-file--4.has-focus + label strong,
.custom-input-file--4:focus + label strong {
    background-color: darken($color-base-1, 15%);
}
@media screen and (max-width: 456px) {
    .custom-input-file--4 + label strong,
    .custom-input-file--4 + label span {
        display: block;
        width: 100%;
    }
}
// User forms (Sign in, register)
.form-card--style-1 {
    background-color: $card-bg;
    border: 1px solid $card-border-color;
}

.form-card--style-1 .form-header {
    padding: ($spacer * 1.2);
    background-color: $card-header-bg;
    border-bottom: 1px solid $card-border-color;
}

.form-card--style-1 .form-header .heading {
    margin: 0;
    padding: 0;
    color: $heading-color;
}

.form-card--style-1 .form-body {
    padding: $spacer * 2;
}

.form-card--style-1 .form-footer {
    padding: $spacer ($spacer * 2);
    background-color: $card-footer-bg;
}

.form-card--style-2 {
    //color: @formUserTextColor;
}

.form-card--style-2 .form-header {
    position: relative;
    padding: ($spacer * 2) 0;
    background-color: $color-base-1;
}

.form-card--style-2 .form-header .form-header-title {
    margin: 0;
    padding: 0;
    color: $color-base-text-1;
}

.form-card--style-2 .form-header:after {
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: 14px;
    top: 100%;
    left: 50%;
    border-top-color: $color-base-1;
    margin-left: -14px;
}

.form-card--style-2 .form-header .form-header-icon i {
    font-size: 3rem;
    color: $color-base-text-1;
}

.form-card--style-2 .form-body {
    padding: $spacer * 2;
    background-color: $card-bg;
}

.form-card--style-2 .form-footer {
    padding: $spacer 0;
    background-color: $card-bg;
}

.form-user-footer-links a {
    font-size: $font-size-xs;
    color: $color-gray-light;
}
// Validation

.help-block.with-errors > ul > li {
    font-size: $font-size-sm;
    color: $color-red;
}

.has-error .form-control:focus {
    box-shadow: none;
    -webkit-box-shadow: none;
    border-color: $color-red;
}

.has-success .form-control:focus {
    box-shadow: none;
    -webkit-box-shadow: none;
    border-color: $color-green;
}

.has-feedback .glyphicon {
    font-family: "Ionicons" !important;
    font-size: 12px;
}

.has-feedback .glyphicon-remove::before {
    content: "\f129";
    color: $color-danger;
}

.has-feedback .glyphicon-ok::before {
    content: "\f121";
    color: $color-success;
}
// Form subscribe large

.form-subscribe-lg .form-control {
    border: 0;
    background: transparent;
    padding: 0.5rem 1rem;
    font-size: 2.5rem;
    font-weight: 300;
    color: rgba(0, 0, 0, 1);
    border-left: 5px solid rgba(0, 0, 0, 0.3);
    border-radius: 0;
}

.form-subscribe-lg .form-control:focus {
    background: transparent;
}

.form-subscribe-lg.form-inverse .form-control {
    border-left: 5px solid rgba(255, 255, 255, 0.3);
    color: rgba(255, 255, 255, 0.9);
}

.form-subscribe-lg.form-inverse .form-control::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.9);
}

.form-subscribe-lg.form-inverse .form-control:-moz-placeholder {
    /* Firefox 18- */
    color: rgba(255, 255, 255, 0.9);
}

.form-subscribe-lg.form-inverse .form-control::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba(255, 255, 255, 0.9);
}

.form-subscribe-lg.form-inverse .form-control:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.9);
}
