.navbar-header {
    float: none;
}

.modal-fullscreen-menu {
    .close {
        opacity: 1;
        padding: 0.875rem;
        position: absolute;
        top: 1rem;
        right: 1rem;
        z-index: 110;
        font-size: 1rem;
        cursor: pointer;
        color: rgba(255, 255, 255, 0.5);
    }
    .close:hover {
        color: rgba(255, 255, 255, 0.8);
    }
    .modal-dialog {
        margin: 0 auto;
        width: 100%;
        max-width: 768px;
        display: flex;
        height: 100%;
        align-items: center;
        z-index: 100;
    }

    .modal-content {
        background-color: transparent;
        box-shadow: none;
        border: none;
    }

    .list-group {
        text-align: center;
        margin: 0 auto;
        width: 100%;

        a {
            font-size: 200%;
            font-weight: 200;
            letter-spacing: 0.05em;
            border: none;
            transition: all 0.25s ease;
            background-color: transparent;
            color: white;
            padding: 7.5vh 0;
            height: 5vh;
            font-size: 5vh;
            line-height: 0;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: white;
                z-index: -1;
                opacity: 0;
                transform: scale3d(0.7, 1, 1);
                transition: transform 0.4s, opacity 0.4s;
            }

            &:hover {
                color: black;

                &:before {
                    transform: translate3d(0, 0, 0);
                    opacity: 1;
                }
            }
        }
    }
}

.modal-fullscreen-menu::before {
    background: rgba(0, 0, 0, 0.9);
    content: '';
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
}
