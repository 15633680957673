@media(min-width: 992px) {
    // Styles applied on the .body-wrap when .navbar-aside is activated
    .body-wrap.has-navbar-aside {
        margin-left: 18.125rem;
    }

    // Styles for navbar-aside
    .navbar-aside {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 18.125rem;
        z-index: 500;
    }

    .navbar-aside .container {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }
    .navbar-aside .navbar {
        height: 100%;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .navbar-aside .navbar .navbar-collapse {
        width: 100%;
        padding: 0 4rem;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .navbar-aside .navbar .navbar-nav {
        width: 100%;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    // Navbar brand
    .navbar-aside .navbar-brand {
        width: 100%;
        padding: 0 4rem;
        margin: 2rem 0;
    }
    // Navbar links
    @media (min-width: 992px) {
        .navbar-aside .navbar-expand-lg .navbar-nav .nav-link {
            margin: 0;
            padding-top: 1rem;
            padding-bottom: 1rem;
            padding-left: 0;
            padding-right: 0;
        }

        .navbar-aside .navbar-expand-lg .navbar-nav .nav-link:hover,
        .navbar-aside .navbar-expand-lg .navbar-nav .nav-link:focus,
        .navbar-aside .navbar-expand-lg .navbar-nav .show .nav-link,
        .navbar-aside .navbar-expand-lg .navbar-nav .show .nav-link:hover
         {
            background: transparent;
            color: $color-base-1;
        }
        .navbar-aside .navbar-expand-lg .navbar-nav .dropdown-toggle::after {
            content: "\f3d3";
            font-family: "Ionicons";
            font-size: 10px;
            margin-left: 0.875rem;
            border: 0 !important;
            position: relative;
            top: 2px;
        }
    }

    // Dropdown menu
    .navbar-aside .navbar .dropdown .dropdown-menu {
        margin-left: 12rem;
        top: 1rem;
    }

    .navbar-aside .navbar .dropdown-submenu .dropdown-menu {
        margin-left: 0;
    }

    // Magemenu
    .navbar-aside .megamenu {
        position: relative;
    }
    .navbar-aside .navbar-expand-lg .navbar-nav .megamenu .dropdown-menu {
        min-width: 900px;
        left: 0;
        right: auto;
    }
}
