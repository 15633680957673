.list-group {
    border-left: 0;
    border-right: 0;
}

.list-group-item {
    padding: 1rem;
    border: 0;
    border-bottom: 1px solid $list-group-border-color;
    color: $color-gray-light;
    font-size: $font-size-sm;
    background-color: $list-group-bg;
    margin-bottom: 0;
}
.list-group-item-action:hover {
    background-color: $list-group-hover-bg;
}
.list-group-item-sm {
    padding: 0.75rem 1rem;
}
.list-group-item:first-child {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-top: 1px solid $border-color-inner;
}

.list-group-item:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.list-group-item-action.active,
.list-group-item-action.active:focus,
.list-group-item-action.active:hover {
    background-color: $list-group-hover-bg;
    color: $color-gray-dark;
    border-color: $list-group-border-color;
}

.list-group-item .label {
    margin-left: $spacer;
}

/* List groups included in cards */
.card .list-group .list-group-item:first-child {
    border-top: 0!important;
}
.card .list-group .list-group-item:last-child {
    border-bottom: 0!important;
}

/* List group components */
.lgi-img {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    vertical-align: top;
    margin: -.1rem 1.2rem 0 -.2rem;
}
.lgi-content {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    min-width: 0;
}
.lgi-heading {
    font-size: $font-size-sm;
    color: $color-gray-dark;
}
.lgi-heading > small {
    float: right;
    color: $color-gray-light;
    font-weight: 500;
}
.lgi-content > p {
    color: $color-gray-light;
    line-height: 1.5;
    margin: .2rem 0 0;
}
