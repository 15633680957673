// Spacing
[class^="space-"] {
    display: block;
}
.space-xs-sm {
    margin-bottom: $spacer * 1.5;
}
.space-xs-md {
    margin-bottom: $spacer * 2.5;
}
.space-xs-lg {
    margin-bottom: $spacer * 3.5;
}
.space-xs-xl {
    margin-bottom: $spacer * 4.5;
}
@media(min-width: 768px) {
    .space-sm-sm {
        margin-bottom: $spacer * 1.5;
    }
    .space-sm-md {
        margin-bottom: $spacer * 2.5;
    }
    .space-sm-lg {
        margin-bottom: $spacer * 3.5;
    }
    .space-sm-xl {
        margin-bottom: $spacer * 4.5;
    }
}
@media(min-width: 992px) {
    .space-md-sm {
        margin-bottom: $spacer * 1.5;
    }
    .space-md-md {
        margin-bottom: $spacer * 2.5;
    }
    .space-md-lg {
        margin-bottom: $spacer * 3.5;
    }
    .space-md-xl {
        margin-bottom: $spacer * 4.5;
    }
}
@media(min-width: 1201px) {
    .space-lg-sm {
        margin-bottom: $spacer * 1.5;
    }
    .space-lg-md {
        margin-bottom: $spacer * 2.5;
    }
    .space-lg-lg {
        margin-bottom: $spacer * 3.5;
    }
    .space-lg-xl {
        margin-bottom: $spacer * 4.5;
    }
}
// Spacing for certain resolutions
@media(max-width: 767px) {
    .space-xs-only-1 {
        clear:both;
        margin-bottom: $spacer;
    }
    .space-xs-only-2 {
        clear:both;
        margin-bottom: $spacer * 2;
    }
    .space-xs-only-3 {
        clear:both;
        margin-bottom: $spacer * 3;
    }
}
@media(min-width: 768px) and (max-width: 991px) {
    .space-sm-only-1 {
        clear:both;
        margin-bottom: $spacer;
    }
    .space-sm-only-2 {
        clear:both;
        margin-bottom: $spacer * 2;
    }
    .space-sm-only-3 {
        clear:both;
        margin-bottom: $spacer * 3;
    }
}
@media(min-width: 992px) and (max-width: 1200px) {
    .space-md-only-1 {
        clear:both;
        margin-bottom: $spacer;
    }
    .space-md-only-2 {
        clear:both;
        margin-bottom: $spacer * 2;
    }
    .space-md-only-3 {
        clear:both;
        margin-bottom: $spacer * 3;
    }
}
@media(min-width: 1201px) {
    .space-lg-only-1 {
        clear:both;
        margin-bottom: $spacer;
    }
    .space-lg-only-2 {
        clear:both;
        margin-bottom: $spacer * 2;
    }
    .space-lg-only-3 {
        clear:both;
        margin-bottom: $spacer * 3;
    }
}
// Margin spacing
.no-margin {
    margin: 0!important;
}
.mt-0 {
    margin-top: 0!important;
}


// Padding spacing
.no-padding {
    padding: 0!important;
}
.pt-0 {
    padding-top: 0!important;
}

// Negative margins and paddings
@media(min-width: 992px) {
    .mt--1 {
        margin-top: -($spacer)!important;
    }
    .mt--2 {
        margin-top: -($spacer * 2)!important;
    }
    .mt--3 {
        margin-top: -($spacer * 3)!important;
    }
    .mt--4 {
        margin-top: -($spacer * 4)!important;
    }
    .mt--5 {
        margin-top: -($spacer * 5)!important;
    }

    .mr--1 {
        margin-right: -($spacer)!important;
    }
    .mr--2 {
        margin-right: -($spacer * 2)!important;
    }
    .mr--3 {
        margin-right: -($spacer * 3)!important;
    }

    .mb--1 {
        margin-bottom: -($spacer)!important;
    }
    .mb--2 {
        margin-bottom: -($spacer * 2)!important;
    }
    .mb--3 {
        margin-bottom: -($spacer * 3)!important;
    }

    .ml--1 {
        margin-left: -($spacer)!important;
    }
    .ml--2 {
        margin-left: -($spacer * 2)!important;
    }
    .ml--3 {
        margin-left: -($spacer * 3)!important;
    }
    .ml--4 {
        margin-left: -($spacer * 4)!important;
    }
    .ml--5 {
        margin-left: -($spacer * 5)!important;
    }

    // Large margins in pixels
    .mt-100 {
        margin-top: 100px!important;
    }
    .mb-100 {
        margin-bottom: 100px!important;
    }
    .mt-150 {
        margin-top: 150px!important;
    }
    .mb-150 {
        margin-bottom: 150px!important;
    }

    .mt-250 {
        margin-top: 250px!important;
    }
    .mb-250 {
        margin-bottom: 250px!important;
    }

    .mt-300 {
        margin-top: 300px!important;
    }
    .mb-300 {
        margin-bottom: 300px!important;
    }

    // Large negative margins in pixels
    .mt--100 {
        margin-top: -100px!important;
    }
    .mb--100 {
        margin-bottom: -100px!important;
    }

    .mt--150 {
        margin-top: -150px!important;
    }
    .mb--150 {
        margin-bottom: -150px!important;
    }

    .mt--300 {
        margin-top: -300px!important;
    }
    .mb--300 {
        margin-bottom: -300px!important;
    }


    // Large margins in pixels
    .pt-100 {
        padding-top: 100px!important;
    }
    .pb-100 {
        padding-bottom: 100px!important;
    }
    .pt-150 {
        padding-top: 150px!important;
    }
    .pb-150 {
        padding-bottom: 150px!important;
    }
    .pt-200 {
        padding-top: 200px!important;
    }
    .pb-200 {
        padding-bottom: 200px!important;
    }
    .pt-250 {
        padding-top: 250px!important;
    }
    .pb-250 {
        padding-bottom: 250px!important;
    }

    .pt-300 {
        padding-top: 300px!important;
    }
    .pb-300 {
        padding-bottom: 300px!important;
    }

    // Large negative paddings in pixels
    .pt--100 {
        padding-top: -100px!important;
    }
    .pb--100 {
        padding-bottom: -100px!important;
    }

    .pt--150 {
        padding-top: -150px!important;
    }
    .pb--150 {
        padding-bottom: -150px!important;
    }

    .pt--300 {
        padding-top: -300px!important;
    }
    .pb--300 {
        padding-bottom: -300px!important;
    }

    // Large margins in pixels
    .rt-150 {
        position: relative;
        top: 150px!important;
    }


}

//  Vh spacing
.top-10vh {
    top: 10vh;
}

// Width
.width-auto {
    width: auto!important;
}
.width-10 {
    width: 10%!important;
}
.width-20 {
    width: 20%!important;
}
.width-30 {
    width: 30%!important;
}
.width-40 {
    width: 40%!important;
}
.width-50 {
    width: 50%!important;
}
.width-60 {
    width: 60%!important;
}
.width-70 {
    width: 70%!important;
}
.width-80 {
    width: 80%!important;
}
.width-90 {
    width: 90%!important;
}
.width-100 {
    width: 100%!important;
}
// Height
.height-auto {
    height: auto!important;
}
// Font sizes
.font-size-1 {
    font-size: 1rem!important;
}
.font-size-2 {
    font-size: 2rem!important;
}
.font-size-3 {
    font-size: 3rem!important;
}
.font-size-4 {
    font-size: 4rem!important;
}
.font-size-5 {
    font-size: 5rem!important;
}
.font-size-6 {
    font-size: 6rem!important;
}
.font-size-7 {
    font-size: 7rem!important;
}
.font-size-8 {
    font-size: 8rem!important;
}
.font-size-9 {
    font-size: 9rem!important;
}
.font-size-10 {
    font-size: 10rem!important;
}

@media(min-width: 992px) {
    .font-size-md-1 {
        font-size: 1rem!important;
    }
    .font-size-md-2 {
        font-size: 2rem!important;
    }
    .font-size-md-3 {
        font-size: 3rem!important;
    }
    .font-size-md-4 {
        font-size: 4rem!important;
    }
    .font-size-md-5 {
        font-size: 5rem!important;
    }
    .font-size-md-6 {
        font-size: 6rem!important;
    }
    .font-size-md-7 {
        font-size: 7rem!important;
    }
    .font-size-md-8 {
        font-size: 8rem!important;
    }
    .font-size-md-9 {
        font-size: 9rem!important;
    }
    .font-size-md-10 {
        font-size: 10rem!important;
    }
}

@media(min-width: 1201px) {
    .font-size-lg-1 {
        font-size: 1rem!important;
    }
    .font-size-lg-2 {
        font-size: 2rem!important;
    }
    .font-size-lg-3 {
        font-size: 3rem!important;
    }
    .font-size-lg-4 {
        font-size: 4rem!important;
    }
    .font-size-lg-5 {
        font-size: 5rem!important;
    }
    .font-size-lg-6 {
        font-size: 6rem!important;
    }
    .font-size-lg-7 {
        font-size: 7rem!important;
    }
    .font-size-lg-8 {
        font-size: 8rem!important;
    }
    .font-size-lg-9 {
        font-size: 9rem!important;
    }
    .font-size-lg-10 {
        font-size: 10rem!important;
    }
}

// Text styles
.text-uppercase {
    text-transform: uppercase!important;
}
.text-capitalize {
    text-transform: capitalize!important;
}
.text-normal {
    text-transform: none!important;
}
.text-line-through {
    text-decoration: line-through;
}
.text-underline {
    text-decoration: underline;
}
.text-underline--none,
.text-underline--none:hover {
    text-decoration: none!important;
}

// Font families
.typeface-base { 
    font-family: $font-family-base!important;
}
.typeface-alt {
    font-family: $font-family-alt!important;
}
.font-blzee {
    font-family: 'Blzee', sans-serif!important;
}
.font-custom-1 {
	font-family: 'Sue Ellen Francisco', sans-serif!important;
}
.font-custom-2 {
	font-family: 'Pacifico', sans-serif!important;
}
.font-custom-3 {
	font-family: 'YellowTail', sans-serif!important;
}
.font-custom-4 {
	font-family: 'Slabo 27px', sans-serif!important;
}

// Standard border
.no-border {
    border: 0!important;
}
.border {
    border: 1px solid;
    border-color: $border-color-base;
}
// [class*="border-"] {
//     border: 1px solid!important;
// }
.border-top {
    border-top: 1px solid $border-color-base;
}
.border-right {
    border-right: 1px solid $border-color-base;
}
.border-bottom {
    border-bottom: 1px solid $border-color-base;
}
.border-left {
    border-left: 1px solid $border-color-base;
}
@media(min-width: 768px) {
    .border-md {
        border: 1px solid $border-color-base;
    }
    .border-md-top {
        border-top: 1px solid $border-color-base;
    }
    .border-md-right {
        border-right: 1px solid $border-color-base;
    }
    .border-md-bottom {
        border-bottom: 1px solid $border-color-base;
    }
    .border-md-left {
        border-left: 1px solid $border-color-base;
    }
}
@media(min-width: 992px) {
    .border-lg {
        border: 1px solid $border-color-base;
    }
    .border-lg-top {
        border-top: 1px solid $border-color-base;
    }
    .border-lg-right {
        border-right: 1px solid $border-color-base;
    }
    .border-lg-bottom {
        border-bottom: 1px solid $border-color-base;
    }
    .border-lg-left {
        border-left: 1px solid $border-color-base;
    }
}
.b-inverse {
    border-color: rgba(255, 255, 255, 0.15);
}
// Radius
.no-radius {
    border-radius: 0!important;
}
// Shadows: used for large section
.sct-shadow-top-1 {
    background-image: url('../images/elements/shadow-1.png');
    background-repeat: no-repeat;
    background-position: top center;
}
.sct-shadow-bottom-1 {
    background-image: url('../images/elements/shadow-1.png');
    background-repeat: no-repeat;
    background-position: bottom center;
}
// Font strength
.strong {
    font-weight: bold!important;
}
.strong-100 {
    font-weight: 100!important;
}
.strong-200 {
    font-weight: 200!important;
}
.strong-300 {
    font-weight: 300!important;
}
.strong-400 {
    font-weight: 400!important;
}
.strong-500 {
    font-weight: 500!important;
}
.strong-600 {
    font-weight: 600!important;
}
.strong-700 {
    font-weight: 700!important;
}
/* LINE SPACING */
.ls-1 {
    letter-spacing: 1px!important;
}
.ls-2 {
    letter-spacing: 2px!important;
}
.ls-3 {
    letter-spacing: 3px!important;
}
.ls-4 {
    letter-spacing: 4px!important;
}
.ls-5 {
    letter-spacing: 5px!important;
}

// Line heights
.line-height-1_2 {
    line-height: 1.2!important;
}
.line-height-1_4 {
    line-height: 1.4!important;
}
.line-height-1_5 {
    line-height: 1.5!important;
}
.line-height-1_6 {
    line-height: 1.6!important;
}
.line-height-1_8 {
    line-height: 1.8!important;
}
// Text
.text-italic {
    font-style: italic!important;
}

// Z-depth for depth illusion using shadows
.z-depth--removed {
    border-width: 1px!important;
    box-shadow: none!important;
}
.z-depth-1:not(.btn),
.z-depth-1--hover:not(.btn):hover,
.z-depth-1-top:not(.btn),
.z-depth-1-top--hover:not(.btn):hover,
.z-depth-1-bottom:not(.btn),
.z-depth-1-bottom--hover:not(.btn):hover,
.z-depth-2:not(.btn),
.z-depth-2--hover:not(.btn):hover,
.z-depth-2-top:not(.btn),
.z-depth-2-top--hover:not(.btn):hover,
.z-depth-2-bottom:not(.btn),
.z-depth-2-bottom--hover:not(.btn):hover,
.z-depth-3:not(.btn),
.z-depth-3--hover:not(.btn):hover,
.z-depth-3-top:not(.btn),
.z-depth-3-top--hover:not(.btn):hover,
.z-depth-3-bottom:not(.btn),
.z-depth-3-bottom--hover:not(.btn):hover,
.z-depth-4:not(.btn),
.z-depth-4--hover:not(.btn):hover,
.z-depth-4-top:not(.btn),
.z-depth-4-top--hover:not(.btn):hover,
.z-depth-4-bottom:not(.btn),
.z-depth-4-bottom--hover:not(.btn):hover,
.z-depth-5:not(.btn),
.z-depth-5--hover:not(.btn):hover,
.z-depth-5-top:not(.btn),
.z-depth-5-top--hover:not(.btn):hover,
.z-depth-5-bottom:not(.btn),
.z-depth-5-bottom--hover:not(.btn):hover {
    border-color: transparent !important;
}

.z-depth-0,
.z-depth-0--hover:hover {
    box-shadow: none!important;
}
.z-depth-1,
.z-depth-1--hover:hover {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1), 0 2px 10px rgba(0, 0, 0, 0.1);
}

.z-depth-1-top,
.z-depth-1-top--hover:hover {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.z-depth-1-bottom,
.z-depth-1-bottom--hover:hover {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.z-depth-2,
.z-depth-2--hover:hover {
    box-shadow: 0 8px 17px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
}
.z-depth-2-top,
.z-depth-2-top--hover:hover {
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
}
.z-depth-2-bottom,
.z-depth-2-bottom--hover:hover {
    box-shadow: 0 8px 17px rgba(0, 0, 0, 0.1);
}
.z-depth-3,
.z-depth-3--hover:hover {
    box-shadow: 0 12px 15px rgba(0, 0, 0, 0.1), 0 17px 50px rgba(0, 0, 0, 0.1);
}
.z-depth-3-top,
.z-depth-3-top--hover:hover {
    box-shadow: 0 17px 50px rgba(0, 0, 0, 0.1);
}
.z-depth-3-bottom,
.z-depth-3-bottom--hover:hover {
    box-shadow: 0 12px 15px rgba(0, 0, 0, 0.1);
}
.z-depth-4,
.z-depth-4--hover:hover {
    box-shadow: 0 16px 28px rgba(0, 0, 0, 0.1), 0 25px 55px rgba(0, 0, 0, 0.1);
}
.z-depth-4-top,
.z-depth-4-top--hover:hover {
    box-shadow: 0 25px 55px rgba(0, 0, 0, 0.1);
}
.z-depth-4-bottom,
.z-depth-4-bottom--hover:hover {
    box-shadow: 0 16px 28px rgba(0, 0, 0, 0.1);
}
.z-depth-5,
.z-depth-5--hover:hover {
    box-shadow: 0 27px 24px rgba(0, 0, 0, 0.1), 0 40px 77px rgba(0, 0, 0, 0.1);
}
.z-depth-5-top,
.z-depth-5-top--hover:hover {
    box-shadow: 0 40px 77px rgba(0, 0, 0, 0.1);
}
.z-depth-5-bottom,
.z-depth-5-bottom--hover:hover {
    box-shadow: 0 27px 24px rgba(0, 0, 0, 0.1);
}

// Radius
.rounded {
    border-radius: $border-radius!important;
}
.rounded-top {
    border-top-left-radius: $border-radius!important;
    border-top-right-radius: $border-radius!important;
}
.rounded-top {
    border-top-left-radius: $border-radius!important;
    border-top-right-radius: $border-radius!important;
}
.rounded-right {
    border-top-right-radius: $border-radius!important;
    border-bottom-right-radius: $border-radius!important;
}

.rounded-bottom {
    border-bottom-right-radius: $border-radius!important;
    border-bottom-left-radius: $border-radius!important;
}

.rounded-left {
    border-top-left-radius: $border-radius!important;
    border-bottom-left-radius: $border-radius!important;
}

// Image
.img-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.img-grayscale {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}
// Other classes
.relative {
    position: relative;
}

// Overflows
.overflow--visible {
    overflow: visible!important;
}
.overflow--hidden {
    overflow: hidden!important;
}

/* Opacities */
.alpha-1,
.alpha-wrapper:hover .alpha-1--hover {
    opacity: 0.1;
}
.alpha-2,
.alpha-wrapper:hover .alpha-2--hover {
    opacity: 0.2;
}
.alpha-3,
.alpha-wrapper:hover .alpha-3--hover {
    opacity: 0.3;
}
.alpha-4,
.alpha-wrapper:hover .alpha-4--hover {
    opacity: 0.4;
}
.alpha-5,
.alpha-wrapper:hover .alpha-5--hover {
    opacity: 0.5;
}
.alpha-6,
.alpha-wrapper:hover .alpha-6--hover {
    opacity: 0.6;
}
.alpha-7,
.alpha-wrapper:hover .alpha-7--hover {
    opacity: 0.7;
}
.alpha-8,
.alpha-wrapper:hover .alpha-8--hover {
    opacity: 0.8;
}
.alpha-9,
.alpha-wrapper:hover .alpha-9--hover {
    opacity: 0.9;
}

/* Positions */
.pos-absolute {
    position: absolute!important;
}
.top-0 {
    top: 0;
}
.top-50px {
    top: 50px;
}
.right-0 {
    right: 0;
}
.bottom-0 {
    bottom: 0;
}
.left-0 {
    left: 0;
}
