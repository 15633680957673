// General styles
body {
	font-size: $font-size-base;
	font-family: $font-family-base;
	color: $color-gray;
}
p:not(.lead) {
	font-size: $paragraph-font-size;
	line-height: $paragraph-line-height;
}
.excerpt,
.excerpt p {
	color: $color-gray;
}
// Links
a {
	transition: color 0.2s;
	-moz-transition: color 0.2s;
	-webkit-transition: color 0.2s; 
}
.link {
	color: $link-color;
	display: inline-block;
	text-decoration: $link-decoration;
	transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
.link > i {
	margin-right: 5px;
	font-size: 0.875rem;
	position: relative;
    top: -1px;
}
.link-sm > i {
	margin-right: 5px;
	font-size: 0.625rem;
	position: relative;
    top: -1px;
}
.link-sm > .icon-lg {
	font-size: 0.875rem;
}
.link:hover {
	color: $link-color-hover;
	text-decoration: $link-decoration-hover;
	text-decoration: underline;
}
.link--style-1 {
	color: $color-gray-dark;
}
.link--style-1:hover {
	color: $color-base-1;
}
.link--style-2 {
	color: $color-base-1!important;
}
.link--style-2:hover {
	color: $color-base-1!important;
	text-decoration: underline!important;
}
.link--style-3 {
	color: $color-gray-light;
}
.link--style-3:hover {
	color: $color-gray-dark;
}
.link--style-4 {
	color: rgba(255, 255, 255, 1);
}
.link--style-4:hover {
	color: rgba(255, 255, 255, 1);
	text-decoration: none;
}
.link--bb {
	padding-bottom: 8px;
	border-bottom: 3px solid rgba(255, 255, 255, 0.3);
}
.link--bb:hover {
	padding-bottom: 8px;
	border-bottom: 3px solid rgba(255, 255, 255, 0.8);
}

.link--bb-1 {
	color: $color-gray-dark;
	padding-bottom: 4px;
	border-bottom: 3px solid $color-base-1;
}
.link--bb-1:hover {
	text-decoration: none;
	color: $color-base-1;
}

.link--line-after {
	color: $color-gray-dark;
	position: relative;
	padding-right: 50px;
}
.link--line-after:after {
	content: '';
	position: absolute;
	top: 50%;
	right: 0;
	width: 40px;
	height: 1px;
	background-color: $color-gray-dark;
}
.link--line-after:hover {
	text-decoration: none;
}

.link-lg {
	font-size: $font-size-lg;
}
.link-sm {
	font-size: $font-size-sm;
}
.link-xs {
	font-size: $font-size-xs;
}
// Headings
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $font-family-alt;
	line-height: 1.46;
}
.heading {
	margin: 0 0 6px;
	padding: 0;
	text-transform: $heading-text-transform;
	font-family: $font-family-alt;
	font-weight: $heading-font-weight;
	color: $heading-color;
	line-height: 1.46;
}
.heading--base {
	font-family: $font-family-base;
}
.heading-light {
	color: $heading-light-color;
}
.heading > a {
	color: $heading-color;
}
.heading:hover > a {
	color: $color-base-1!important;
}

.heading-inverse {
	color: $heading-inverse-color;
}
.heading-inverse > a {
	color: $heading-inverse-color;
}
.heading-inverse:hover > a {
	color: $color-base-1!important;
}

.heading > .icon,
.heading > a > .icon {
	display: inline-block;
	margin-right: 4px;
}
.heading-count {
	font-size: $font-size-sm;
	margin-right: 0.625rem;
}
.heading-1 {
	font-size: $font-size-h1!important;
	line-height: 1.3;
}
.heading-2 {
	font-size: $font-size-h2!important;
	line-height: 1.3;
}
.heading-3 {
	font-size: $font-size-h3!important;
	line-height: 1.3
}
.heading-4 {
	font-size: $font-size-h4!important;
}
.heading-5 {
	font-size: $font-size-h5!important;
}
.heading-6 {
	font-size: $font-size-h6!important;
}

@media(max-width: 991px) {
	.heading-responsive.heading-1 {
		font-size: ($font-size-h1 / 1.5)!important;
		line-height: 1.3;
	}
	.heading-responsive.heading-2 {
		font-size: $font-size-h2!important;
		line-height: 1.3;
	}
	.heading-responsive.heading-3 {
		font-size: $font-size-h3!important;
		line-height: 1.3
	}
	.heading-responsive.heading-4 {
		font-size: $font-size-h4!important;
	}
	.heading-responsive.heading-5 {
		font-size: $font-size-h5!important;
	}
	.heading-responsive.heading-6 {
		font-size: $font-size-h6!important;
	}
}
.heading-xs {
	font-size: $font-size-xs!important;
}
.heading-sm {
	font-size: $font-size-sm!important;
}
.heading-lg {
	font-size: $font-size-lg * 2!important;
}
.heading-xl {
	font-size: $font-size-lg * 3!important;
}
.heading-xl-x2 {
	font-size: $font-size-lg * 6!important;
}
.heading-xxl {
	font-size: $font-size-lg * 4!important;
}

@media(max-width: 991px) {
	.heading-responsive.heading-xl {
		font-size: $font-size-lg * 1.5!important;
	}
	.heading-responsive.heading-xl-x2 {
		font-size: $font-size-lg * 3!important;
	}
	.heading-responsive.heading-xxl {
		font-size: $font-size-lg * 2!important;
	}
}

/* Text sizes */
.text-xs {
	font-size: 0.625rem!important;
}
.text-sm {
	font-size: 0.75rem!important;
}
.text-md {
	font-size: 0.875rem!important;
}
.text-lg {
	font-size: 1rem!important;
}


// Fluid paragraph containers
.fluid-paragraph {
	font-size: $font-size-base;
	width: 680px;
	margin: auto;
	padding: 0 20px;
	position: relative;
}
.fluid-paragraph h1:not(.heading),
.fluid-paragraph h2:not(.heading),
.fluid-paragraph h3:not(.heading),
.fluid-paragraph h4:not(.heading),
.fluid-paragraph h5:not(.heading),
.fluid-paragraph h6:not(.heading) {
	text-transform: none!important;
}
.fluid-paragraph-xs {
	width: 480px;
}
.fluid-paragraph-sm {
	width: 580px;
}
.fluid-paragraph-md {
	width: 780px;
}
.fluid-paragraph-lg {
	width: 880px;
}
.paragraph-xs > p {
	font-size: 0.8rem;
	line-height: 1.4;
}
.paragraph-sm > p {
	font-size: $font-size-sm;
}
.paragraph-lg > p {
	font-size: $font-size-lg;
}
.paragraph-excerpt {
	line-height: 1.6;
}
@media (max-width: 767px) {
	.fluid-paragraph {
		width: 100% !important;
	}
}

// Quote symbols
.quote-block-lg {
	line-height: 2.4rem;
}
.quote-icon {
	font-size: 2rem;
	display: inline-block;
}
.quote-icon--1::before {
	content: "\f347";
	font-family: "Ionicons";
	color: inherit;
}
.quote-icon-md {
	font-size: 3rem;
}
.quote-icon-lg {
	font-size: 4rem;
}
.quote-icon-xl {
	font-size: 5rem;
}

// Other
small,
.small {
	font-size: 70%;
}
strong {
	font-weight: 600;
}

// Blockquotes
blockquote .blockquote-source {
	font-size: $font-size-sm;
}
blockquote .blockquote-source::before {
    content: '\2014 \00A0';
}
.blockquote-custom {
	border: 0;
	position: relative;
}
.blockquote--style-1 {
	border: 0;
	font-family: 'Pacifico', sans-serif;
	font-size: 1.5rem;
}

.blockquote--style-2 {
	border: 0;
    font-size: 1.3rem;
	color: $color-gray-light;
}

.blockquote--style-2:before {
    content: '\201C';
    position: absolute;
    left: -82px;
    top: -52px;
    font-size: 9em;
    opacity: 0.2;
    font-family: Georgia, "Times New Roman", Times, seri
}
.blockquote--style-2:after {
    content: '\201D';
    position: absolute;
    right: -82px;
    top: -52px;
    font-size: 9em;
    opacity: 0.2;
    font-family: Georgia, "Times New Roman", Times, serif;
}
// Icon pictograms
.icon-pic--style-1 {
	color: $color-base-1;
}
.icon-pic > i {
	font-size: 2.5rem;
}
.icon-pic > img {
	width: 2.5rem;
}

.icon-pic-md > i {
	font-size: 5rem;
}
.icon-pic-md > img {
	width: 5rem;
}
.icon-pic-lg > i {
	font-size: 10rem;
}
.icon-pic-lg > img {
	width: 10rem;
}

.icon-pic-xl > i {
	font-size: 20rem;
}
.icon-pic-xl > img {
	width: 20rem;
}
