.global-search {
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 600;
    display: none;
    opacity: 0;
    -webkit-transition: all 0.8s ease-out;
    -moz-transition: all 0.8s ease-out;
    transition: all 0.8s ease-out;
}
.header-inner + .container > .global-search {
    position: absolute;
    border-top: 1px solid $navbar-border-color;
}
.header-affix.affix .global-search {
    display: none;
}
.header-affix.affix [data-toggle="global-search"] {
    display: none;
}
.global-search.in {
    display: block;
}
.global-search.animated {
    opacity: 1;
}
.global-search.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
}
.global-search.fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
}

.navbar.global-search-active {
    padding: 30px 0;

}

.navbar.global-search-active * {
    opacity: 0!important;
}

// Form
.global-search .form-global-search {
    height: 100%;
    position: relative;
    z-index: 700;
    background: $navbar-bg;
}
.top-navbar + .header-1 .global-search {
    margin-top: 48px;
}
.navbar--inverse + .global-search .form-global-search {
    background: $navbar-dark-bg;
}
.global-search .form-global-search .search-input {
    display: block;
    width: 100%;
    margin: 54px 0;
    height: 40px;
    padding: 0 50px 0 0;
    font-size: 22px;
    color: $navbar-nav-link-color;
    font-family: $font-family-alt;
    border: 0;
    background-color: transparent;
    background-image: none;
}
.navbar--inverse + .global-search .form-global-search .search-input {
    color: $navbar-dark-nav-link-color;
}
.navbar--sm + .global-search .form-global-search .search-input {
    margin: 42px 0;
}
.global-search .form-global-search .search-input:focus {
    outline: 0;
    font-weight: 500;
}
.global-search .form-global-search .search-input::-moz-placeholder {
    color: #999;
    opacity: 1;
    font-weight: 400;
}
.global-search .form-global-search .search-input::-moz-placeholder:focus {
    color: #999;
    font-weight: 400;
}
.global-search .form-global-search .search-input:-ms-input-placeholder {
    color: #999;
    font-weight: 400;
}
.global-search .form-global-search .search-input::-webkit-input-placeholder {
    color: #999;
    font-weight: 400;
}
.global-search .form-global-search .search-input:focus::-webkit-input-placeholder {
    color: transparent;
}
.global-search .form-global-search .btn {
    display: block;
    width: 100%;
    height: 40px;
    margin-top: 20px;
    line-height: 40px;
    padding: 0;
}
.global-search-backdrop {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: rgba(255, 255, 255, 0.95);
}
.global-search.in .global-search-backdrop {
    display: block;
}
.global-search-close-btn {
    position: absolute;
    top: 50%;
    margin-top: -18px;
    right: 15px;
    width: 36px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    color: #999;
    background: transparent;
    border: 0;
    z-index: 900;
}

// Search: Full screen
.global-search-fullscreen {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 500px;
    height: 100%;
    z-index: 1500;
    background: rgba(255, 255, 255, 0.95);
}
.global-search-fullscreen .form-global-search {
    width: 80%;
    margin-left: 10%;
    position: absolute;
    top: 50%;
    margin-top: -75px;
}
.global-search-fullscreen .form-global-search .search-input {
    display: block;
    width: 100%;
    margin-top: 25px;
    height: 50px;
    padding: 0 0 3px;
    font-size: 28px;
    line-height: 1.42857143;
    color: #111;
    border: 0;
    border-bottom: 1px solid #111;
    background-color: transparent;
    background-image: none;
}
.global-search-fullscreen .form-global-search .search-input:focus {
    border-color: #111;
    outline: 0;
}
.global-search-fullscreen .form-global-search .search-input::-moz-placeholder {
    color: #111;
    opacity: 1;
}
.global-search-fullscreen .form-global-search .search-input::-moz-placeholder:focus {
    color: #111;
}
.global-search-fullscreen .form-global-search .search-input:-ms-input-placeholder {
    color: #111;
}
.global-search-fullscreen .form-global-search .search-input::-webkit-input-placeholder {
    color: #111;
}
.global-search-fullscreen .form-global-search .search-input:focus::-webkit-input-placeholder {
    color: transparent;
}
.global-search-fullscreen .form-global-search .btn {
    height: 50px;
    margin-top: 25px;
    padding: 14px 36px;
    float: right;
    font-size: 18px;
    line-height: 0 !important;
}
.global-search-fullscreen .close-search {
    position: relative;
    z-index: 300;
    padding: 20px;
    background-color: #111;
    float: right;
    cursor: pointer;
    border-bottom-left-radius: 4px;
}
.global-search-fullscreen .close-search:before {
    content: "\f00d";
    font-family: FontAwesome;
    color: #fff;
}

// Global search: Overlay
.global-search-overlay {
    padding-top: 30px;
    height: auto;
    background: transparent;
}
.global-search-overlay .form-global-search {
    background: #fff;
    border-radius: $border-radius;
}
.global-search-overlay .form-global-search .search-input {
    display: block;
    width: 100%;
    margin: 0;
    padding: 30px 0;
    height: auto;
    font-size: 20px;
    line-height: 1.42857143;
    color: #111;
    border: 0;
    background-color: transparent;
    background-image: none;
}
.global-search-overlay .close-search {
    position: absolute;
    top: 50%;
    right: 20px;
    z-index: 300;
    width: 32px;
    height: 32px;
    line-height: 32px;
    margin-top: -16px;
    text-align: center;
    color: #ddd;
    float: right;
    cursor: pointer;
    border-bottom-left-radius: 4px;
}
.global-search-overlay .close-search:before {
    content: "\f00d";
    font-family: FontAwesome;
    color: #ddd;
}
