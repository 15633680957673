/* No UI Slider */
.noUi-target,
.noUi-target * {
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -ms-touch-action: none;
    touch-action: none;
    -ms-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.noUi-target {
    position: relative;
    direction: ltr;
}

.noUi-base {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
}

.noUi-connect {
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
}

.noUi-origin {
    position: absolute;
    height: 0;
    width: 0;
}

.noUi-handle {
    position: relative;
    z-index: 1;
}

.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
    -webkit-transition: top 0.3s, right 0.3s, bottom 0.3s, left 0.3s;
    transition: top 0.3s, right 0.3s, bottom 0.3s, left 0.3s;
}

.noUi-state-drag * {
    cursor: inherit!important;
}

.noUi-base,
.noUi-handle {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.noUi-horizontal {
    height: 18px;
}

.noUi-horizontal .noUi-handle {
    width: 34px;
    height: 28px;
    left: -17px;
    top: -6px;
}

.noUi-vertical {
    width: 18px;
}

.noUi-vertical .noUi-handle {
    width: 28px;
    height: 34px;
    left: -6px;
    top: -17px;
}

.noUi-target {
    background: #FAFAFA;
    border-radius: 4px;
    border: 1px solid #D3D3D3;
    box-shadow: inset 0 1px 1px #F0F0F0, 0 3px 6px -5px #BBB;
}

.noUi-connect {
    background: #3FB8AF;
    box-shadow: inset 0 0 3px rgba(51, 51, 51, .45);
    -webkit-transition: background 450ms;
    transition: background 450ms;
}

.noUi-draggable {
    cursor: ew-resize;
}

.noUi-vertical .noUi-draggable {
    cursor: ns-resize;
}

.noUi-handle {
    border: 1px solid #D9D9D9;
    border-radius: 3px;
    background: #FFF;
    cursor: default;
    box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #EBEBEB, 0 3px 6px -3px #BBB;
}

.noUi-active {
    box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB;
}

.noUi-handle:after,
.noUi-handle:before {
    content: "";
    display: block;
    position: absolute;
    height: 14px;
    width: 1px;
    background: #E8E7E6;
    left: 14px;
    top: 6px;
}

.noUi-handle:after {
    left: 17px;
}

.noUi-vertical .noUi-handle:after,
.noUi-vertical .noUi-handle:before {
    width: 14px;
    height: 1px;
    left: 6px;
    top: 14px;
}

.noUi-vertical .noUi-handle:after {
    top: 17px;
}

[disabled] .noUi-connect {
    background: #B8B8B8;
}

[disabled] .noUi-handle,
[disabled].noUi-handle,
[disabled].noUi-target {
    cursor: not-allowed;
}

.noUi-pips,
.noUi-pips * {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.noUi-pips {
    position: absolute;
    color: #999;
}

.noUi-value {
    position: absolute;
    text-align: center;
}

.noUi-value-sub {
    color: #ccc;
    font-size: 10px;
}

.noUi-marker {
    position: absolute;
    background: #CCC;
}

.noUi-marker-large,
.noUi-marker-sub {
    background: #AAA;
}

.noUi-pips-horizontal {
    padding: 10px 0;
    height: 80px;
    top: 100%;
    left: 0;
    width: 100%;
}

.noUi-value-horizontal {
    -webkit-transform: translate3d(-50%, 50%, 0);
    transform: translate3d(-50%, 50%, 0);
}

.noUi-marker-horizontal.noUi-marker {
    margin-left: -1px;
    width: 2px;
    height: 5px;
}

.noUi-marker-horizontal.noUi-marker-sub {
    height: 10px;
}

.noUi-marker-horizontal.noUi-marker-large {
    height: 15px;
}

.noUi-pips-vertical {
    padding: 0 10px;
    height: 100%;
    top: 0;
    left: 100%;
}

.noUi-value-vertical {
    -webkit-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
    padding-left: 25px;
}

.noUi-marker-vertical.noUi-marker {
    width: 5px;
    height: 2px;
    margin-top: -1px;
}

.noUi-marker-vertical.noUi-marker-sub {
    width: 10px;
}

.noUi-marker-vertical.noUi-marker-large {
    width: 15px;
}

.noUi-tooltip {
    display: block;
    position: absolute;
    border: 1px solid #D9D9D9;
    border-radius: 3px;
    background: #fff;
    color: #000;
    padding: 5px;
    text-align: center;
}

.noUi-horizontal .noUi-tooltip {
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    left: 50%;
    bottom: 120%;
}

.noUi-vertical .noUi-tooltip {
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    top: 50%;
    right: 120%;
}

.noUi-target {
    border-radius: 0;
    box-shadow: none;
    border: 0;
    background: #e8e8e8;
    margin: 15px 0;
}

.noUi-horizontal {
    height: 2px;
}

.noUi-horizontal .noUi-handle {
    top: -5px;
    left: -1px;
}

.noUi-vertical {
    width: 3px;
}

.noUi-connect {
    background: $color-base-1;
    box-shadow: none;
}

.noUi-horizontal .noUi-handle,
.noUi-vertical .noUi-handle {
    width: 12px;
    height: 12px;
    border: 0;
    border-radius: 100%;
    box-shadow: none;
    cursor: pointer;
    position: relative;
    background-color: $color-base-1;
    transition: box-shadow 0.2s,-webkit-transform 0.2s;
    transition: box-shadow 0.2s,transform 0.2s;
    transition: box-shadow 0.2s,transform 0.2s,-webkit-transform 0.2s;
}

.noUi-horizontal .noUi-handle:after,
.noUi-horizontal .noUi-handle:before,
.noUi-vertical .noUi-handle:after,
.noUi-vertical .noUi-handle:before {
    display: none;
}

.noUi-horizontal .noUi-handle.noUi-active,
.noUi-vertical .noUi-handle.noUi-active {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
}

.noUi-horizontal .noUi-active,
.noUi-vertical .noUi-active {
    box-shadow: 0 0 0 10px rgba(0,0,0,.04);
}

.input-slider--blue .noUi-connect {
    background: #03A9F4;
}

.input-slider--blue.noUi-horizontal .noUi-handle,
.input-slider--blue.noUi-vertical .noUi-handle {
    background-color: #03A9F4;
}

.input-slider--red .noUi-connect {
    background: #ff5652;
}

.input-slider--red.noUi-horizontal .noUi-handle,
.input-slider--red.noUi-vertical .noUi-handle {
    background-color: #ff5652;
}

.input-slider--amber .noUi-connect {
    background: #ffc107;
}

.input-slider--amber.noUi-horizontal .noUi-handle,
.input-slider--amber.noUi-vertical .noUi-handle {
    background-color: #ffc107;
}

.input-slider--green .noUi-connect {
    background: #32c787;
}

.input-slider--green.noUi-horizontal .noUi-handle,
.input-slider--green.noUi-vertical .noUi-handle {
    background-color: #32c787;
}
/* Disabled state */
[disabled] .noUi-connect,
[disabled].noUi-connect {
    background: $noui-slider-connect-disabled-bg;
}

[disabled] .noUi-handle,
[disabled].noUi-origin {
    cursor: not-allowed;
}
/* Range slider value labels */
.range-slider-value {
    font-size: $font-size-sm;
    font-weight: 500;
}

.range-slider-wrapper {}

.range-slider-wrapper .price-currency {}

.range-slider-wrapper .upper-info {
    font-weight: 400;
    margin-bottom: 5px;
}

.input-slider-value-output {
    background: #333;
    color: #fff;
    padding: 4px 8px;
    position: relative;
    top: 12px;
    font-size: 11px;
    border-radius: 2px;
}

.input-slider-value-output:after {
    bottom: 100%;
    left: 10px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: #333;
    border-width: 4px;
    margin-left: -4px;
}

.input-slider-value-output.left:after {
    left: 10px;
    right: auto;
}

.input-slider-value-output.right:after {
    right: 10px;
    left: auto;
}
