/* CAROUSEL - STYLE 1 */
.carousel--style-1 .carousel-control {
	position: absolute;
	top: 50%;
	bottom: auto;
	left: 0;
	width: 50px;
	height: 50px;
	line-height: 50px;
	margin-top: -25px;
	font-size: 20px;
	background: $carousel-control-bg;
	color: $carousel-control-color;
	text-align: center;
	text-shadow: 0 1px 2px rgba(0, 0, 0, .6);
	filter: alpha(opacity=80);
	opacity: 0.8;
	-webkit-transition: all 100ms linear;
	transition: all 100ms linear;
}

.carousel--style-1 .carousel-control.right {
	right: 0;
	left: auto;
	border-top-left-radius: $border-radius;
	border-bottom-left-radius: $border-radius;
}

.carousel--style-1 .carousel-control.left {
	left: 0;
	right: auto;
	border-top-right-radius: $border-radius;
	border-bottom-right-radius: $border-radius;
}

.carousel--style-1 .carousel-control:hover {
	width: 70px;
	background: $carousel-control-bg-hover;
	color: $carousel-control-color-hover;
	filter: alpha(opacity=100);
	opacity: 1;
}

.carousel--style-1 .carousel-inner .carousel-item img {
	//max-width: 100%;
}
// Carousel: STYLE 1 (variation 1)
.carousel--style-1-v1 .carousel-control {
	opacity: 0;
}

.carousel--style-1-v1:hover .carousel-control {
	opacity: 1;
}
// Carousel: STYLE 2
.carousel--style-2 .carousel-control {
	position: absolute;
	top: 50%;
	bottom: auto;
	left: 0;
	width: 50px;
	height: 50px;
	line-height: 50px;
	margin-top: -25px;
	font-size: 20px;
	background: rgba(37,37,37,0.8);
	color: #fff;
	text-align: center;
	text-shadow: 0 1px 2px rgba(0,0,0,0.6);
	filter:alpha(opacity=80);
	opacity: 0.8;
	-webkit-transition: all 100ms linear;
	transition: all 100ms linear;
}

.carousel--style-2 .carousel-control.right {
	right: 0;
	left: auto;
	border-top-left-radius: 2px;
	border-bottom-left-radius: 2px;
}

.carousel--style-2 .carousel-control.left {
	left: 0;
	right: auto;
	border-top-right-radius: 2px;
	border-bottom-right-radius: 2px;
}

.carousel--style-2 .carousel-control:hover {
	width: 70px;
	background: #1f3a93;
	color: #fff;
	filter:alpha(opacity=100);
	opacity: 1;
}

.carousel--style-2 .carousel-inner .carousel-item img {
	max-width: 100%;
}

.carousel--style-2 .carousel-indicators {
	position: absolute;
	bottom: -5px;
	left: 50%;
	width: 60%;
	padding-left: 0;
	margin-left: -30%;
	text-align: center;
	list-style: none;
}

.carousel--style-2 .carousel-caption {
	text-shadow: 0;
	text-align: left;
	background: rgba(0,0,0,0.5);
    background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%);
    background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr= '#00000000', endColorstr='#a6000000',GradientType=0 );
	color: #fff;
}

.carousel--style-2 .carousel-caption {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 20px 30px;
}

.carousel--style-2 .carousel-caption .caption-title {
	display: block;
	margin: 10px 0 0;
	padding: 0;
	font-size: 22px;
	line-height: 1.1;
	color: #fff;
	font-weight: 500;
}

.carousel--style-2 .carousel-caption .caption-subtitle {
	display: block;
	margin: 10px 0 0;
	padding: 0;
	font-size: 16px;
	line-height: 1.1;
	color: #ddd;
	font-weight: 400;
}

.carousel--style-2 .carousel-caption .meta-info-cells li,
.carousel--style-2 .carousel-caption .meta-info-cells li a,
.carousel--style-2 .carousel-caption .meta-info-cells li i {
	color: #ddd;
}
