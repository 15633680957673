// Dropdown
.dropdown-menu {
    border: 1px solid #eceff1;
    border-radius: 1px;
    line-height: 1;
    min-width: 12rem;
    box-shadow: 0 5px 25px 0 rgba(123,123,123,.15);
}

.dropdown-menu {
    font-size: 0.9rem;
}
@media (min-width:992px) {
    .dropdown-menu {
        display: block;
        opacity: 0;
        visibility: hidden;
        z-index: 1;
    }
    .dropdown--animated .dropdown-menu {
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
    }
}

.dropdown-menu .dropdown-item {
    padding: 0.875rem 1rem;
    font-size: $font-size-sm;
}
.dropdown-menu .dropdown-item:active,
.dropdown-menu .dropdown-item:hover {
    background-color: rgba(162,162,162,.1);
    color: inherit;
}

.dropdown-menu .dropdown-item > i {
    margin-right: 1rem;
    font-size: 1.3rem;
    line-height: .75em;
    vertical-align: -17%;
}
.dropdown-header {
    padding-left: 1rem;
    padding-right: 1rem;
}

// Dropown: Sizes
.dropdown-menu.dropdown-menu-sm {
    min-width: 100px;
    border: $border-radius-xs;
}

.dropdown-menu.dropdown-menu-lg {
    min-width: 260px;
    border-radius: $border-radius;
}

.dropdown-menu.dropdown-menu-xl {
    min-width: 320px;
    border-radius: $border-radius;
}

// Dropdown components
.dropdown--style-2 .dropdown-toggle {
    display: block;
    padding: 0 1rem;
    text-decoration: none;
    color: inherit;
}
.dropdown--style-2 .dropdown-toggle::after {
    border: 0;
    margin: 0;
    content: none;
}
.dropdown--style-2 .dropdown-image {
    width: 32px;
    height: 32px;
    border-radius: $border-radius;
}
.dropdown--style-2 .dropdown-image.rounded-circle {
    border-radius: 50%;
}
.dropdown--style-2 .dropdown-icon {
    font-size: 1.5rem;
    text-align: center;
}

.dropdown--style-2 .has-badge .badge {
    position: absolute;
    //top: 18%;
    left: 38px;
    font-weight: 300;
}

.dropdown--style-2 .has-notification .badge {
    padding: 0;
    margin: 0;
    display: block;
    position: absolute;
    left: 50%;
    top: 0px;
    font-weight: 300;
    width: 6px;
    height: 6px;
    margin-left: -3px;
    border-radius: 6px;
}

.dropdown--style-2 .dropdown-text {
	font-size: $font-size-sm;
    margin-left: 0.5rem;
    display: inline-block;
}

// Dropdown menu inverse
.dropdown-menu.dropdown-menu-inverse {
    background: #282f37!important;
    border-color: #242a31!important;
}

.dropdown-menu.dropdown-menu-inverse .dropdown-item {
    color: #dadada!important;
}

.dropdown-menu.dropdown-menu-inverse .dropdown-item:active,
.dropdown-menu.dropdown-menu-inverse .dropdown-item:focus,
.dropdown-menu.dropdown-menu-inverse .dropdown-item:hover {
    color: #fff!important;
    background: #31353e!important;
}

.dropdown-menu.dropdown-menu-inverse .dropdown-divider {
    background: #191e23;
}
@media (min-width:992px) {
    .show > .dropdown-menu {
        opacity: 1;
        visibility: visible;
        z-index: 1000;
        -moz-transform: none;
        -o-transform: none;
        -ms-transform: none;
        -webkit-transform: none;
        transform: none;
    }
}

.dropdown-submenu .dropdown-toggle i {
    font-size: 10px;
    position: absolute;
    right: 14px;
    top: 8px;
    color: #a3a7bd;
}
@media (max-width:991px) {
    .dropdown-submenu .dropdown-menu {
        border-color: #e8e8e8;
    }

    .dropdown-submenu .dropdown-menu .dropdown-item {
        padding-left: 2rem;
    }
}
@media (min-width:992px) {
    .dropdown-submenu .dropdown-menu {
        left: 98%;
        top: -2px;
        border-radius: 0.25rem!important;
    }
}

.dropdown-extend {
    position: initial;
}

.dropdown-extend-menu {
    width: 100%;
}
@media (min-width:768px) {
    .dropdown-extend-menu {
        padding: 20px 30px;
    }
}
@media (min-width:768px) {
    .dropdown-extend-menu .dropdown-item {
        margin: 5px 0;
    }
}

.dropdown-extend-menu .dropdown-item i {
    margin-right: 3px;
    font-size: 14px;
    color: #6b7386;
}
@media (max-width:767px) {
    .dropdown-extend-menu .dropdown-item i {
        display: none;
    }
}
