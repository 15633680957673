// Inline list
.list-inline li {
    display: inline-block;
    float: left;
}
// Icon list
ul.icons {
    margin: 0;
    padding: 0;
}

ul.icons li {
    list-style: none;
    margin: 5px 0 0;
    vertical-align: top;
    font-size: $font-size-sm;
}

ul.icons li > a:not(.icon) {
    color: $color-gray-light;
}

ul.icons li > a:hover {
    color: $color-base-1;
}

ul.icons li .icon {
    margin-right: 1rem;
    display: inline-block;
    font-size: 20px;
    width: 20px;
    position: relative;
    top: 4px;
}
// Inline links
ul.inline-links {
    margin: 0;
    padding: 0;
    list-style: none;
    display: inline-block;
    line-height: 1;
}

ul.inline-links > li {
    display: inline-block;
    padding: 0 .4em 0 .4em;
    position: relative;
    font-size: $font-size-xs;
}

ul.inline-links > li:first-child {
    padding-left: 0;
}

ul.inline-links > li:last-child {
    padding-right: 0;
}

ul.inline-links > li,
ul.inline-links > li > a {
    color: $color-gray-light;
}

ul.inline-links > li > a.active {
    color: $color-gray-dark;
    font-weight: 500;
}

ul.inline-links > li > a:hover {
    color: $color-base-1;
    text-decoration: none;
}
ul.inline-links > li > a:hover > heading {
    color: $color-base-1;
}

ul.inline-links > li > a > i,
ul.inline-links > li > i {
    margin-right: 4px;
}

ul.inline-links.inline-links--style-2 > li,
ul.inline-links.inline-links--style-2 > li > a {
    font-size: 13px;
}
ul.inline-links.inline-links--style-2 > li:not(:last-child) {
    padding-right: 12px;
}
ul.inline-links.inline-links--style-2 > li:before {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    content: "\f111";
    font-family: 'FontAwesome';
    font-size: 4px;
    color: $color-base-1;
}

ul.inline-links.inline-links--style-2 > li:last-child:before {
    content: "";
}

// Social icons
ul.social-icons {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

ul.social-icons li {
    display: inline-block;
    font-size: $font-size-sm;
}

ul.social-icons li a {
    display: block;
    height: 32px;
    width: 32px;
    text-align: center;
    line-height: 32px;
}

ul.social-icons li.text {
    height: 32px;
    padding-left: 10px;
    line-height: 32px;
}

ul.social-icons li.facebook:hover {
    background: $color-facebook;
    color: $color-facebook-text;
}

ul.social-icons li.facebook:hover > a {
    color: $color-facebook-text;
}

ul.social-icons li.twitter:hover {
    background: $color-twitter;
    color: $color-twitter-text;
}

ul.social-icons li.twitter:hover > a {
    color: $color-twitter-text;
}

ul.social-icons li.linkedin:hover {
    background: $color-linkedin;
    color: $color-linkedin-text;
}

ul.social-icons li.linkedin:hover > a {
    color: $color-linkedin-text;
}

// Social buttons - Footer
.list-social-buttons {
    margin: 0;
    padding: 0;
    list-style: none;
    display: table;
    width: 100%;
}

.list-social-buttons > li {
    border-right: 1px solid $border-color-base;
    display: table-cell;
}

.list-social-buttons > li > a {
    padding: 2rem;
    font-size: 20px;
    display: block;
    text-align: center;
    color: $color-gray-dark;
}

.list-social-buttons > li:last-child {
    border-right: 0;
}

// Categories list
ul.categories {
    padding: 0;
    margin: 0;
    list-style: none;
}

ul.categories > li {
    border-bottom: 1px solid $border-color-base;
}
ul.categories > li:last-child {
    border: 0;
}

ul.categories > li > a {
    display: block;
    padding: 0.75rem 0;
    color: $color-gray-light;
    font-size: $font-size-sm;
    font-family: $font-family-alt;
}

ul.categories > li > a:after,
ul.categories > li > a:before {
    content: "";
    display: table;
}

ul.categories > li > a:after {
    clear: both;
}

ul.categories > li:hover > a {
    color: $color-base-1;
    text-decoration: none;
}

ul.categories > li > ul {
    padding-left: 3rem;
    list-style: none;
}
ul.categories > li > ul > li > a {
    display: block;
    padding: 0.3125rem 0;
    color: $color-gray-light;
    font-size: $font-size-sm;
    font-family: $font-family-alt;
}

ul.categories > li > ul > li > a.active {
    color: $color-base-1;
}

ul.categories--style-1 > li > a {
    padding-left: 20px;
    position: relative;
    display: flex;
    align-items: center;
}

ul.categories--style-1 > li > a > .category-name {
    color: $color-gray-dark;
    flex-grow: 1;
}

ul.categories--style-1 > li > a:hover > .category-name {
    color: $color-base-1;
}

ul.categories--style-1 > li > a > .category-count {
    color: $color-gray-light;
    font-size: $font-size-xs;
}

ul.categories--style-1 > li > a:before {
    content: "\f125";
    font-family: "Ionicons";
    position: absolute;
    height: 16px;
    line-height: 16px;
    left: 0;
    top: 50%;
    margin-top: -8px;
    font-size: 10px;
    color: $color-gray-light;
    -webkit-transition: all 0.1s linear;
    transition: all 0.1s linear;
}

ul.categories--style-2 > li {
    border: 0;
}
ul.categories--style-2 > li > a {
    padding: 5px 0;
}

ul.categories--style-3 > li {
    border: 0;
}
ul.categories--style-3 > li > a {
    display: block;
    padding: 0.625rem 1rem;
}
ul.categories--style-3 > li > a:hover {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: $btn-border-radius;
    color: $color-base-1;
    font-weight: 600;
}
ul.categories--style-3 > li > a.active {
    color: $color-base-1;
    font-weight: 600;
}

ul.categories--style-3 > li i {
    margin-right: 0.75rem;
    position: relative;
    top: 2px;
    font-size: 1.25rem;
}

ul.categories--style-3 > li > ul > li > a.active {
    color: $color-base-1;
    font-weight: 600;
}

.list-wrapper {
    background: $color-base-1;
    padding: $spacer * 1.5;
    border-radius: $card-border-radius;
}

.list-wrapper > .heading {
    color: $color-base-text-1;
    margin-bottom: $spacer;
}

.list-wrapper ul.categories--style-1 > li {
    border-color: rgba(255, 255, 255, 0.3);
    color: $color-base-text-1;
}

.list-wrapper ul.categories--style-1 > li > a,
.list-wrapper ul.categories--style-1 > li > a > .category-count,
.list-wrapper ul.categories--style-1 > li > a > .category-name {
    color: $color-base-text-1;
}

.list-wrapper ul.categories--style-1 > li > a:hover,
.list-wrapper ul.categories--style-1 > li > a:hover > .category-name {
    color: $color-base-text-1;
}

.list-wrapper ul.categories--style-1 > li > a:before {
    color: $color-base-text-1;
}

ul.categories--style-1 > li > a:hover:before {
    left: 5px;
}

// Unstyles list for when included in a .sidebar
.sidebar .list-unstyled {
    font-size: $font-size-sm;
}

.sidebar .list-unstyled > li > a {
    color: $color-gray-dark;
    padding: 0.2rem 0;
    display: block;
}

.sidebar .list-unstyled > li:hover > a {
    color: $color-base-1;
}

// PSEUDO LIST - VERTICAL INFO
.vertical-info {
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
}

.vertical-info > li {
    padding: 0.25rem 0;
}

.vertical-info > li > span {
    display: inline-block;
    font-size: $font-size-sm;
}

.vertical-info > li > .vi-title {
    font-weight: 600;
    margin-right: 0.5rem;
}

.vertical-info > li > .vi-content {
    color: $color-gray-light;
}
// Bullet list

ul.list-bullet {
    list-style: none;
    margin: 0;
    padding: 0;
    background: transparent;
}

ul.list-bullet li {
    clear: left;
    padding: 10px 0;
    display: block;
    width: 100%;
}

ul.list-bullet li > figure {
    margin: 0;
    padding: 0;
    border-radius: 100%;
    width: 36px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    font-size: $font-size-base;
    color: $color-gray-lighter;
    font-weight: 600;
    display: inline-block;
    float: left;
}

ul.list-bullet li img {
    width: 60px;
}

ul.list-bullet li h3 {
    font-size: 16px;
    font-weight: 600;
    margin-left: 15px;
    display: inline-block;
}

ul.list-bullet li p {
    margin: 0 0 0 50px;
    padding: 0;
}

ul.list-bullet li span {
    margin-left: 6px;
}

ul.list-bullet li a {
    font-weight: 500;
}

ul.list-bullet li a:hover {
    text-decoration: none;
    color: #464646;
}

ul.list-bullet li span {
    font-size: 12px;
}

// List: COMMENTS
.list-recent-stories {
    background: #e6edf2;
}

.list-recent .widget-title {
    padding: 26px 24px 28px;
}

.list-recent {
    list-style: none;
    margin: 0;
    padding: 0;
}
.list-recent li {
    padding: 10px 0;
    border-bottom: 1px solid $border-color-inner;
}

.list-recent li:first-child {
    padding-top: 0;
    border-top: 0;
}

.list-recent.list-recent-boxed li {
    padding: 10px 15px;
}

.list-recent li:last-child {
    border: 0;
}

.list-recent-stories li:nth-child(odd) {
    background: #c6d3dd;
}

.list-recent-comments li:nth-child(odd) {
    background: $color-gray-lighter;
}
.list-recent .inline-links {
    display: block;
}
.list-recent .post-thumb {
    float: left;
    width: 72px;
    height: 72px;
    margin-right: 16px;
}

.list-recent-stories .post-thumb,
.list-recent-stories .post-thumb img {
    border-radius: 50%;
}

.list-recent-comments li:nth-child(even) .post-thumb {
    float: right;
    margin-right: 0;
    margin-left: 16px;
}

.list-recent .post-thumb img {
    display: block;
    width: 100%;
    height: 100% !important;
}

.list-recent .post-author,
.list-recent .post-title {
    display: block;
    margin-bottom: 0;
}

.list-recent .post-author {
    font-size: 12px;
    color: $color-base-1;
}

.list-recent .post-author a,
.list-recent .post-title,
 {
    font-size: $font-size-sm;
    font-weight: 500;
    line-height: 19px;
    color: $color-gray-dark;
}
.list-recent .post-author a:hover {
    color: $color-base-1;
}
.list-recent .post-title a {
    color: $color-gray-dark;
}
.list-recent .post-title a:hover {
    color: $color-base-1;
}
.list-recent .post-title .label {
    margin-top: 5px;
}
.list-recent .post-title > a + .inline-links,
.list-recent .post-title > a + .star-rating {
    margin-top: 5px;
}
.list-recent .post-entry,
.list-recent .post-desc {
    display: block;
    font-size: 12px;
    color: $color-gray-light;
    line-height: 17px;
}

.list-recent .post-meta-bot {
    padding: 21px 0;
    text-align: center;
}
@media (max-width: 479px), (min-width: 768px) and (max-width: 991px) {
    .list-recent li {
        padding: 8px;
    }

    .list-recent .post-thumb {
        margin-right: 5px;
        margin-top: 3px;
    }

    .list-recent-comments li:nth-child(odd) .post-thumb {
        margin-left: 5px;
    }
}

// List border styles
ul.list-border--dotted > li {
    border: 1px solid dotted!important;
}
ul.list-border--dotted > li:last-child {
    border: 0;
}
// List elements
.list-icon {
    display: inline-block;
    //float: left;
    margin-right: 10px;
}
.list-icon--style-1 {
    width: 36px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    border: 2px solid $border-color-base;
    border-radius: 100%;
    color: $color-gray-light;
}
.list-icon--style-1 i {
    color: $color-base-1;
}
 /* List simple */
.list-simple {
    padding: 0;
    margin: 0;
    list-style: none;
}

.list-simple--1 li {
    font-size: 1.125rem;
    line-height: 1.75;
    margin-top: 0.75rem;
}
/* List symbols */
.list-symbol--1 {
    list-style-type: square;
}

/* Meta */
.meta-category {
    display: block;
    position: relative;
    z-index: 100;
}
.meta-category a {
    position: relative;
    display: inline-block;
    margin-right: 10px;
    color: $color-base-1;
}
.meta-category a:hover {
    color: darken($color-base-1, 15%);
}
