$pell-content-height: 500px;

@import "~bootstrap/scss/bootstrap";
@import "../boomerang/boomerang";
@import "~select2/src/scss/core";
@import "~select2-bootstrap-theme/dist/select2-bootstrap";
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~jquery-datetimepicker/build/jquery.datetimepicker.min';
@import "~toastr/build/toastr";
@import '~pell/src/pell';
@import "datatables";

html, body {
  height: 100%;
}

body.login {
  background: url('../images/login-bg.png') no-repeat;
  background-size: cover;

  nav.navbar {
    border-bottom: none;
    margin-bottom: 20vh;
  }

  .form-signin {
    width: 100%;
    max-width: 480px;
    padding: 20px 20px 25px 20px;
    margin: auto;
    background: rgba(255, 255, 255, 0.9);
    border: 1px solid #E9E9E9;
    box-sizing: border-box;
    backdrop-filter: blur(10px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 6px;
  }
  .form-signin .checkbox {
    font-weight: 400;
  }
  .form-signin .form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    font-size: 16px;
  }
  .form-signin .form-control:focus {
    z-index: 2;
  }
  .form-signin input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .form-signin .btn {
    min-width: 120px;
  }
}

nav.navbar {
  border-bottom: 1px solid #E9E9E9;
  padding: 5px 0;

  .navbar-brand {
    padding: 0;
    display: inline-block;

    object {
      padding: 10px 10px 12px 10px;
    }
  }

  ul.nav {
    margin-top: 0px;

    li {
      margin-left: 0.5em;
    }
  }
}

body.admin {
  .navbar-brand {
    margin-bottom: 1em;
    margin-left: 0.6em;
  }
}

.btn {
  border-radius: 100px;
}

h4 {
  color: #000;
  font-family: helvetica, verdana, arial, sans-serif;
  font-size: 22px;
  line-height: 114%;
}

.container-fluid {
  padding-left: 40px;
}

.footer {
  background: none;
  color: #000;
  font-size: 16px;
  font-family: "PT Sans";
}

.actions {
  max-width:100%;
  white-space:nowrap;
}

.course {
  height: 180px;
  color: #000;

  &[data-type=offline]  {
    background: url('../images/course.svg') no-repeat;
    background-size: cover;
  }

  &[data-type=online]  {
    background: url('../images/course-red.svg') no-repeat;
    background-size: cover;
  }

  h4 {
    margin-bottom: 0.3em;
  }

  a {
    color: #000;
  }

  &:hover {
    -moz-box-shadow: 0 0 0.5em #ccc;
    -webkit-box-shadow: 0 0 0.5em #ccc;
    box-shadow: 0 0 0.5em #ccc;
  }
}

h4 a {
  font-size: 80%;
}

.courses {
  li {
    margin-bottom: 2em;
  }
}

.courses {
  .course {
    margin-bottom: 1em;
  }
}

h4 {
  .filter-group {
    .btn {
      padding: 0.2rem 0.3rem;
      color: #00A3FF;
      background: #fff;
      border-radius: 0.3em;

      &.active {
        background: #00A3FF;
        color: #fff;
      }
    }
  }
}

div {
  .filter-group {
    .btn {
      padding: 0.2rem 0.3rem;
      color: #00A3FF;
      background: #fff;
      border-radius: 0.3em;

      &.active {
        background: #00A3FF;
        color: #fff;
      }
    }
  }
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff !important;
}

.nav-pills .nav-link:focus, .nav-pills .nav-link:hover {
  color: #fff !important;
}

.nav .nav-link.active {
  color: #000;
}

.conversation-messages {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.chat-area {
  overflow-y: scroll;
  height: calc(100vh - 113px);
  height: calc(var(--vh, 1vh) * 100 - 113px);
  box-sizing: border-box;

  .message-container {
    @extend .d-flex, .p-2;
    @media screen and (min-width: 1280px){
      max-width: 750px;
    }
    max-width: 600px;
  }

  .message {
    @extend .card;
    border: none;
  }

  .question {
    @extend .message;
    background-color: #F5F5F5;
  }

  .notification {
    @extend .message;
    background-color: #E6F5FD;
  }
}

.chat-container {
  .message-input {
    @extend .form-control;
    border: none;
    resize: none;
    max-height: 54px;
  }

  .message-input:invalid { padding-top: 15px; }

  ::-moz-placeholder { /* Mozilla Firefox 19+ */
    font-size: 1rem;
  }

  ::-webkit-input-placeholder { /* Webkit */
    font-size: 1rem;
  }

  :-ms-input-placeholder { /* IE */
    font-size: 1rem;
  }

  .last-message-time {
    @extend .pb-0;
    margin-top: -7px;
  }
}

.student-notifications {
  .controls-panel {
    @extend .d-flex, .align-items-center, .border-bottom;
    height: 49px;
  }

  .chat-area-student {
    @extend .chat-area;
    height: calc(100vh - 155px);
    height: calc(var(--vh, 1vh) * 100 - 155px);
  }
}

.notifications-area {
  @extend .chat-area;
  height: calc(var(--vh, 1vh) * 100 - 52px);
}

.message-preview-container {
  .message-preview {
    @extend .p-2, .mr-2, .d-flex;
  }

  .message-preview-active {
    @extend .p-2, .mr-2, .d-flex, .rounded;
    background-color: #FBF6F2;
  }
}

.fully-centered {
  @extend .text-center;
  position: relative;
  top: 50%;
  -webkit-transform: translate(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.field_with_errors { display: inline; }

.pell-content {
  @extend .border-bottom, .border-left, .border-right;
}


.datetime-picker::-moz-placeholder { /* Mozilla Firefox 19+ */
  text-align: right;
}

.datetime-picker::-webkit-input-placeholder { /* Webkit */
  text-align: right;
}

.datetime-picker:-ms-input-placeholder { /* IE */
  text-align: right;
}

.form-signin.form-inline {
  & label {
    justify-content: left;
    font-weight: bold;
  }
}

.nav-item {
  position: relative;

  .badge {
    border-radius: 50%;
    margin-bottom: 1em;
    position: absolute;
    top: 0;
    right: 6px;
  }
}

.tab-content {
  padding-top: 1em;
}

.badge-responsible {
  background: #E6F5FD;
  font-family: helvetica, verdana, arial, sans-serif;
  font-size: 12px;
  color: #666;
  margin-top: 0.5em;
  border-radius: 0.5em;
  padding: 0.3em 0.7em;
}

.terminal {
  background: #000;
  color: #fff;

  a {
    margin: 1em 0;
  }
}

.spinner-status {
  display: inline-block;
  width: $spinner-width / 4;
  height: $spinner-height / 4;
  vertical-align: text-bottom;
  background-color: currentColor;
  // stylelint-disable-next-line property-blacklist
  border-radius: 50%;
}

.btn-sort, .btn-sort:focus {
  text-decoration: none !important;
  text-align: left;
  padding: 6px 15px 6px 0;

  .sort-arrow {
    position: absolute;
    top: 9px;
    right: 0;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}

table.disabled {
  a {
    color: grey;
    cursor: not-allowed;
    pointer-events: none;
  }

  animation: blink 1.5s ease-in infinite;
  -webkit-animation: blink 1.5s ease-in infinite;
}

.blink {
  animation: blink 0.5s ease-in infinite;
  -webkit-animation: blink 0.5s ease-in infinite;
}

@keyframes blink {
  50% {
    opacity: 0.6;
  }
}
@-webkit-keyframes blink {
  50% {
    opacity: 0.6;
  }
}

.open-tinybox {
  cursor: pointer;
  display: block;
}

.text-pre {
  white-space: pre;
}

.text-pre-wrap {
  white-space: pre-wrap;
}
