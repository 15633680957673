/*
Theme: Boomerang - Multipurpose Bootstrap Theme
Website URI: https://wrapbootstrap.com/theme/boomerang-multipurpose-bootstrap-theme-WB021609D
Author: Webpixels
Author URI: https://www.webpixels.io
*/

// SASS variables
@import "variables/_variables.scss";
@import "variables/_custom.scss";

// Navigations
@import "header/_nav-default.scss"; 
@import "header/_nav-aside.scss";
@import "header/nav-fullscreen.scss";

// Main styles
@import "core/_base.scss";

// User's custom styles
@import "core/_custom.scss";
