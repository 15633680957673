.mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}
// Mask: BASE COLOR 1
.mask-base-1--style-1 {
    background: rgba(red($color-base-1), green($color-base-1), blue($color-base-1), 0.9);
}
.mask-base-1--style-2 {
    background: rgba(red($color-base-1), green($color-base-1), blue($color-base-1), 0.5);
}
.mask-base-1--style-3 {
    background: rgba(red($color-base-1), green($color-base-1), blue($color-base-1), 0.7);
}

// Mask: BASE COLOR 2
.mask-base-2--style-1 {
    background: rgba(red($color-base-2), green($color-base-2), blue($color-base-2), 0.9);
}
.mask-base-2--style-2 {
    background: rgba(red($color-base-2), green($color-base-2), blue($color-base-2), 0.5);
}
.mask-base-2--style-3 {
    background: rgba(red($color-base-2), green($color-base-2), blue($color-base-2), 0.7);
}

// Mask: GRADIENT 1
.mask-gradient-1--style-1 {
    @extend .bg-gradient-1;
    opacity: 0.9;
}
.mask-gradient-1--style-2 {
    @extend .bg-gradient-1;
    opacity: 0.5;
}

// Mask: LIGHT
.mask-light{
    background: rgb(255, 255, 255);
}
.mask-light--style-1 {
    background: rgba(255, 255, 255, 0.9);
}
.mask-light--style-2 {
    background: rgba(255, 255, 255, 0.4);
}
.mask-light--style-3 {
    background: rgba(255, 255, 255, 0.2);
}

.mask-light ~ .mask-container .heading {
    color: #333!important;
}
.mask-light ~ .mask-container p {
    color: #555!important;
}

// Mask: DARK
.mask-dark {
    background: rgb(0, 0, 0);
}
.mask-dark--style-1 {
    background: rgba(0, 0, 0, 0.9);
}
.mask-dark--style-2 {
    background: rgba(0, 0, 0, 0.4);
}
.mask-dark--style-3 {
    background: rgba(0, 0, 0, 0.2);
}
.mask-dark ~ .mask-container .heading {
    color: #FFF;
}
.mask-dark ~ .mask-container p {
    color: #d3d3d3;
}
