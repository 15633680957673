/*

---- SUMMARY ----

1. Quick stats
2. Charts
3. Widgets
4. Profile

*/

/* ---- 1. Quick stats ---- */
.quick-stat {
    padding: 1.5rem;
    border-radius: $card-border-radius;
    position: relative;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
}
.quick-stat-info,
.quick-stat-icon,
.quick-stat-chart {
    display: inline-block;
    vertical-align: middle;
}

/* Quick Stat - Variation 1 */
.quick-stat--1 .quick-stat-icon {
    padding: 1.5rem;
    background: #f9f9f9;
    position: absolute;
    right: -80px;
    bottom: -80px;
    border-radius: 100%;
    width: 160px;
    height: 160px;
    line-height: 60px;
    text-align: center;
}
.quick-stat--1 .quick-stat-icon > i {
    font-size: 40px;
    margin-left: -60px;
}
.quick-stat--1.bg-blue {
    background-color: transparent!important;
    border: 1px solid transparentize($color-blue, 0.7);
}
.quick-stat--1.bg-blue .quick-stat-icon {
    background: transparentize($color-blue, 0.9);
    color: transparentize($color-blue, 0.5);
}

.quick-stat--1.bg-orange {
    background-color: transparent!important;
    border: 1px solid transparentize($color-orange, 0.7);
}
.quick-stat--1.bg-orange .quick-stat-icon {
    background: transparentize($color-orange, 0.9);
    color: transparentize($color-orange, 0.5);
}

.quick-stat--1.bg-green {
    background-color: transparent!important;
    border: 1px solid transparentize($color-green, 0.7);
}
.quick-stat--1.bg-green .quick-stat-icon {
    background: transparentize($color-green, 0.9);
    color: transparentize($color-green, 0.5);
}

.quick-stat--1.bg-red {
    background-color: transparent!important;
    border: 1px solid transparentize($color-red, 0.7);
}
.quick-stat--1.bg-red .quick-stat-icon {
    background: transparentize($color-red, 0.9);
    color: transparentize($color-red, 0.5);
}

/* Profile sidebar */
.profile.stacked-left .sidebar {
    margin-left: -15px;
}
.profile.stacked-right .sidebar {
    margin-right: -15px;
}

@media(max-width: 991px) {
    .profile.stacked-left .sidebar,
    .profile.stacked-right .sidebar {
        margin-left: -15px;
        margin-right: -15px;
    }
}

@media(min-width: 992px) {
    .profile.stacked-left .main-content {
        margin-left: 15px;
    }

    .profile.stacked-right .main-content {
        margin-left: 15px;
    }
}

/* Profile picture */
.profile-picture-wrapper {
    position: relative;
}

.profile-picture img {
    max-width: 100%;
}

.profile-picture.profile-picture--style-1 {
    padding: $spacer $spacer 0;
}

.profile-picture.profile-picture--style-1 img {
    border-radius: $border-radius;
}

.profile-picture .btn-aux {
    display: none;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    line-height: 40px;
    text-align: center;
    position: absolute;
    top: 35px;
    right: 50px;
    background: rgba(0, 0, 0, 0.8);
    color: #ccc;
}

.profile-picture:hover .btn-aux {
    display: block;
}

.profile-picture.btn-aux:hover {
    background: $color-base-1;
    color: $color-base-text-1;
}

.profile-picture.profile-picture--style-2 {
    margin: 0 auto;
    width: 140px;
    position: relative;
}

.profile-picture.profile-picture--style-2 img {
    border-radius: 100%;
    margin-top: 30px;
    border: 8px solid rgba(0, 0, 0, 0.1);
}

.sidebar-inverse .profile-picture.profile-picture--style-2 img {
    border-color: rgba(255, 255, 255, 0.1);
}

.profile-picture.profile-picture--style-2 .btn-aux {
    right: 10px;
    top: 10px;
}

.profile-details {
    text-align: center;
    margin-top: 20px;
}

.profile-details .profile-name {
    margin: 0;
    padding: 0;
}

.profile-details .profile-occupation {
    margin: 12px 0 0;
    padding: 0;
}

.profile-details .profile-location {
    margin: 2px 0 0;
    padding: 0;
}

.profile-connect {
    text-align: center;
    width: 180px;
    margin: ($spacer * 2) auto;
}

.profile-stats {
    text-align: center;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.profile-stats .stats-entry {
    padding: 20px 0;
    width: 50%;
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    display: inline-block;
    float: left;
}

.profile-stats .stats-entry:last-child {
    border-right: 0;
}

.profile-stats .stats-entry .stats-count {
    display: block;
    font-weight: 500;
    font-size: $font-size-h4;
}

.profile-stats .stats-entry .stats-label {
    display: block;
    font-weight: 300;
    font-size: $font-size-xs;
}

.profile-useful-links:nth-child(n+2):not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.profile-useful-links .useful-links {
    padding: $spacer * 2;
}

.profile-useful-links:last-child .useful-links {
    //padding-bottom: 0;
}

.profile-useful-links .useful-links a {
    display: block;
    margin-bottom: 10px;
    font-size: $font-size-sm;
}

.profile-useful-links .useful-links a:last-child {
    margin-bottom: 0;
}

.profile-useful-links .useful-links a > i {
    margin-right: 6px;
}

.short-info {
    margin-bottom: $spacer * 1.5;
}

.short-info:last-child {
    margin: 0;
}

.short-info .short-info-label {
    padding: 0;
    margin: 0;
    display: block;
    font-size: $font-size-xs;
    color: $color-gray-light;
    font-weight: 400;
}

.short-info .short-info-title {
    padding: 0;
    margin: 0;
    display: block;
    font-size: $font-size-h6;
    color: $color-gray-dark;
    font-weight: 500;
}

.short-info .short-info-subtitle {
    padding: 0;
    margin: 0;
    display: block;
    font-size: $font-size-h6;
    color: $color-gray-light;
    font-weight: 300;
}

.short-info i {
    font-size: 36px;
    display: inline-block;
}

.short-info .short-info-img {
    width: 50px;
}

.short-info .short-info-img img {
    max-width: 100%;
    border-radius: 100%;
}

.short-info .short-info-offset {
    display: inline-block;
    padding-left: 16px;
    margin-bottom: 15px;
    position: relative;
}

.short-info:last-child .short-info-offset {
    margin-bottom: 0;
}

.short-info .short-info-offset .btn {
    position: relative;
    float: right;
}

/* Page titles */
.inline-actions .ia-item {
    display: inline-block;
    margin-right: 1rem;
    padding: 0;
}
.inline-actions .ia-item > a {
    padding: 0;
}
.inline-actions .ia-item:last-child {
    margin: 0;
}
